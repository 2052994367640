import React, { Component, useEffect, useState } from 'react';
import '../../assets/style.css';
import withRouter from '../../components/withRouter';
import * as moment from 'moment';
import { crudAlertControl, initDataTable, fetchApi } from "../../Functions.js";
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
moment.locale('nl');

const TipsDetails = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [tip, setTip] = useState([]);
    const [modules, setModules] = useState([]);

    const changeData = async (event) => {
        console.log(event);
        event.preventDefault();

        const data = await fetchApi("mba", `/mba/tip/${props.router.params.id}/update`, "GET", {
            "fronttext": event.target.fronttext.value, "moduleid": event.target.moduleid.value, "backtext": event.target.backtext.value
        });

        await getData();
        crudAlertControl('update', data);
    }

    const getData = async () => {
        const [getAllTips, getTipsModules] = await Promise.all([
            fetchApi("mba", `/mba/tip/${props.router.params.id}`, "GET", {
                "tipid": props.router.params.id
            }),
            fetchApi("mba", "/mba/modules/get", "GET")
        ]);

        setTip(getAllTips[0]);
        setModules(getTipsModules);
        initDataTable();
    }

    useEffect(() => {
        getData(true);
    }, [])

    return (
        <React.Fragment>
            <div className='loc_content'>
                <form type="POST" onSubmit={(event) => changeData(event)}>
                    <input className="btn btn-save" style={{ marginTop: '0' }} type="submit" name="submit" value={t('save')} />
                    <h1><button className="goBackBtn" onClick={() => navigate('/manage/tips')}><i class="fas fa-arrow-circle-left"></i></button> {t('tips')} {t('configuration')}</h1>

                    <div class="alert alert-danger" role="alert">
                        {t('somethingwrong')}
                    </div>

                    <div class="orgDetails">
                    <div className="table-responsive">
                        <table class="table">
                            <tr class="roweven">
                                <td class="td">{t('front')}</td>
                                <td class="td"><input type="text" name="fronttext" id="fronttext" defaultValue={tip.fronttext} className="form-control" /></td>
                            </tr>
                            <tr class="roweven">
                                <td class="td">{t('module')}</td>
                                <td class="td">
                                <select name="moduleid" id="moduleid" defaultValue={tip.module} className="form-control">
                                    <option value="0">{t('select')}</option>

                                    {modules.map((module) => {
                                        return (<option value={module.id}>{t(`${module.slug}`)}</option>);
                                    })}
                                </select>
                                </td>
                            </tr>
                            <tr class="roweven">
                                <td class="td">{t('back')}</td>
                                <td class="td"><input type="text" name="backtext" id="backtext" defaultValue={tip.backtext} className="form-control" /></td>
                            </tr>
                            
                        </table>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}

export default withRouter(TipsDetails);
