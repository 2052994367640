import React, { Component, useState, useEffect } from 'react';
import '../../../assets/style.css';
import withRouter from '../../../components/withRouter';
import * as moment from 'moment';
import { fillDataTableFilters, calcAlarmDuration } from "../../../Functions.js";
import LocationAlert from "../../../components/LocationAlert.js";
import Filter from '../../../components/Filter';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Export from '../../../components/Export.js';
moment.locale('nl');

const ReportHistoryAlarmsTech = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [alarms, setAlarms] = useState([]);

    const getTechAlarmsOfDate = async (date, init) => {
        navigate('/reports/historyalarmstech/' + moment(date).format('YYYY-MM-DD'));
        const functions = await import("../../../Functions.js");
        const data = await functions.fetchApi("mba", "/mba/report/historyalarmtech", "GET", {
            "reportdate": moment(date).format('YYYY-MM-DD')
        });
        setAlarms(data);
        functions.initDataTable();
    }

    useEffect(() => {
        getTechAlarmsOfDate(props.router.params.reportdate);
    }, [])

    return (
        <React.Fragment>
        <LocationAlert />

        <div className='loc_content'>
            <h1>{t('historytechalarms')}</h1>

            <div class="filters">
                <Filter type="date" text={t('date')} callback={(data, e) => getTechAlarmsOfDate(data)} />
                <Filter type="select" text={t('type')} id="typeAlarm" column="1" item="reporthistoryalarm-alarmtype" exact={false} />
                <Filter type="select" text={t('object')} id="objectAlarm" column="0" item="reporthistoryalarm-alarmobject" exact={false} />
                <Export />
            </div>

            <div className="DataTable_Container">
            <div className="table-responsive">
                <table id="DataTable" class="showHead table responsive nowrap" width="100%">
                    <thead>
                        <tr>
                            <th>{t('object')}</th>
                            <th>{t('type')}</th>
                            <th>{t('createdon')}</th>
                            <th>{t('confirmedon')}</th>
                            <th>{t('solvedon')}</th>
                            <th>{t('canceledon')}</th>
                            <th>{t('duration')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {alarms.map((alarm, i) => {
                            console.log(alarm);
                            fillDataTableFilters(document.getElementById('typeAlarm'), alarm.alarmtype);
                            fillDataTableFilters(document.getElementById('objectAlarm'), alarm.object);

                            return (
                                <tr onClick={() => { navigate(`/reports/historyalarmstech/alarm/${alarm.objectid}`) }}>
                                    <td>{alarm.object} ({alarm.objectid})</td>
                                    <td>{t(`${alarm.alarmslug}`)}</td>
                                    <td>{alarm.uptriggered ? moment(alarm.uptriggered).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                    <td>{alarm.downtriggered ? moment(alarm.downtriggered).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                    <td>{alarm.finishtriggered ? moment(alarm.finishtriggered).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                    <td>{alarm.canceltriggered ? moment(alarm.canceltriggered).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                    <td>{calcAlarmDuration(alarm)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    </React.Fragment>
  );
}

export default withRouter(ReportHistoryAlarmsTech);
