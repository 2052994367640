import React, { Component, useEffect, useState } from 'react';
import '../../assets/style.css';
import withAuth from '../../components/Login/withAuth';
import { crudAlertControl, fetchApi } from '../../Functions';
import LocationAlert from '../../components/LocationAlert';
import { useTranslation } from 'react-i18next';

const Settings = () => {
    const { t } = useTranslation();
    const [settings, setSettings] = useState([]);

    const setField = async (field, value) => {
        const fielddata = await fetchApi("mba", "/mba/settings/update/field", "GET", {
            "fieldid": field, "fieldvalue": value
        })
        crudAlertControl('update', fielddata);
    }

    const getData = async () => {
        const fieldsdata = await fetchApi("mba", "/mba/settings/get", "GET");
        setSettings(fieldsdata);
    };

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className="loc_content">
                <h1>{t('settings')}</h1>

                <div className="alert alert-success alert-update" role="alert">
                    {t('settingsupdated')}
                </div>

                <div class="alert alert-danger" role="alert">
                    {t('somethingwrong')}
                </div>

                <div className="settingsTable" style={{ background: '#ffffff' }}>
                <div className="table-responsive">
                    <table id="DataTable" className="table responsive nowrap sortTable" width="100%">
                        <thead style={{ display: 'none' }}>
                            <tr class="rowtitle">
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {settings.map(field => {
                                if(field.type === 'bool'){
                                    return(
                                        <tr>
                                            <td>{field.param}</td>
                                            <td>
                                            <label className='switch'>
                                                <input key={`${Math.floor((Math.random() * 1000))}-min`} id={field.param} defaultChecked={field.value == 1 ? true : false} onChange={(event) => setField(field.id, event.target.checked === true ? "1" : "0")} className={field.param} type='checkbox' />
                                                <span className='slider round'></span>
                                            </label>
                                            </td>
                                        </tr>
                                    )
                                }else{
                                    return(
                                        <tr>
                                            <td>{field.param}</td>
                                            <td><input key={`${Math.floor((Math.random() * 1000))}-min`} className='form-control' defaultValue={field.value} type='text' /> <i onClick={(event) => setField(field.id, event.target.parentNode.firstChild.value)} class="fas fa-save"></i></td>
                                        </tr>
                                    )
                                }
                            })}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withAuth(Settings);