import React, { Component, useEffect, useState } from 'react';
import '../../assets/style.css';
import withRouter from '../../components/withRouter';
import { initDataTable, bulkSelect, confirmDelete, crudAlertControl, fetchApi } from "../../Functions.js";
import ModalDelete from "../../components/ModalDelete";
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const OrganisationItems = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [items, setItems] = useState([]);
    const [locations, setLocations] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [current, setCurrent] = useState('');
    const [showModal, setShowModal] = useState(false);
    var lastID = '';
    const bulk = [];
    var CurrentObjectName = '';
    var CurrentObject = props.router.params.id;

    const deleteItem = async (row, objectid, name) => {
        const data = await fetchApi("mba", `/mba/organization/delete/${objectid}`, "GET", {
            "objectid": objectid, "name": name,
        });
        const mbasettings = await fetchApi("mba", "/mba/settings/mba-templates", "GET");
        
        if(data === "OK" && mbasettings[0].value === '1'){
            const rule = await fetchApi("mba", "/mba/admin/responserule/object/delete", "GET", {
                "objectid": objectid,
            });
        }
        crudAlertControl('delete', data);
        await getData();
    }

    const bulkDeleteItems = async (event, array) => {
        array.forEach(async (item) => {
            const data = deleteItem(item, '');
            crudAlertControl('delete', data);
        })
        await getData();
    }

    const bulkChangeLocation = async (event, array) => {
        array.forEach(async (item) => {
            const data = await fetchApi("mba", `/mba/organization/update/${item}/location`, "GET", {
                "objectid": item, "locationid": event.target.value
            });
            crudAlertControl('update', data);
        })
        await getData();
    }

    const bulkChangeRoute = async (event, array) => {
        array.forEach(async (item) => {
            const data = await fetchApi("mba", `/mba/organization/update/${item}/route`, "GET", {
                "objectid": item, "routeid": event.target.value
            });
            crudAlertControl('update', data);
        })
        await getData();
    }

    const addItem = async (event) => {
        event.preventDefault();

        const data = await fetchApi("mba", "/mba/organization/insert", "GET", {
            "objectid": props.router.params.id, "relatedid": event.target.related.value, "name": event.target.name.value, "routeid": event.target.route.value
        });
        const mbasettings = await fetchApi("mba", "/mba/settings/mba-templates", "GET");

        console.log(data);

        if(mbasettings[0].value === '1'){
            const rule = await fetchApi("mba", "/mba/admin/responserule/template/", "GET", {
                "deviceid": data[0].id, "devicetypeid": props.router.params.id, "objectid": event.target.related.value,
            });
        }

        if (props.router.params.id === 4 || props.router.params.id === 5) {
            const objectId = props.router.params.id === 4 ? props.router.params.id : lastID;
            [11, 12, 13].forEach(async (paramId) => 
            await fetchApi("mba", "/mba/organization/objectparams/insert", "GET", {
                "objectid": objectId, "name": event.target.name.value, 'objectparamtype': paramId, 'objectparamvalue': ''
            }));
        }

        if(data && data.length > 0){
            navigate('/manage/organisation/details/' + data[0].id + '/' + props.router.params.id);
        }

        return false;
    }

    const getBreadcrumbs = async (object, relatedid, depth = 0) => {
        var structure = [];
        var level0 = await fetchApi("mba", `/mba/${object}/get/${relatedid}`, "GET");
      
        if(level0 && level0.length > 0 && depth == 2){
          structure.push({
            floor:{
              floorid: level0[0].floorid,
              floorname: level0[0].floorname,
            },
            building: {
              buildingid: level0[0].relatedid,
              buildingname: level0[0].relatedname
            }
          })
        }
    }

    const getData = async (init) => {
        var Objects = ['location', 'building', 'floor', 'room', 'employee', 'client', 'sharedspace'];

        const [data, routes, objects] = await Promise.all([
            fetchApi("mba", `/mba/${Objects[(CurrentObject - 1)]}/get`, "GET", {
                "locationid": localStorage.getItem('currentLocation')
            }),
            fetchApi("mba", "/mba/route/get/", "GET", {
                "locationid": localStorage.getItem('currentLocation')
            }),
            fetchApi("mba", "/mba/object/get", "GET", {
                "locationid": localStorage.getItem('currentLocation')
            })
        ])

        const objectitems = await Promise.all(data.map(async object => {
            const bread = CurrentObject == 1 || CurrentObject == 2 || CurrentObject == 3 ? await getBreadcrumbs(Objects[(CurrentObject - 1)], object.relatedid, 1) : await getBreadcrumbs(Objects[(CurrentObject - 2)], object.relatedid, 2);
            const breadcrumb = bread && bread[0] ? bread[0].floor.floorname + " > " + bread[0].building.buildingname : '';

            return {
                ...object,
                breadcrumb: CurrentObject != 3 || CurrentObject != 1 ? breadcrumb : []
            }
        }))
        
        setItems(objectitems);
        setRoutes(routes);
        setLocations(objects);
        setCurrent(Objects[(CurrentObject - 1)]);
        initDataTable();
    }

    useEffect(() => {
        getData();
    }, [])

    const objectNames = {
        location: t('location'),
        building: t('building'),
        floor: t('floor'),
        room: t('room'),
        employee: t('employee'),
        client: t('client'),
        sharedspace: t('sharedspace')
    };

    CurrentObjectName = objectNames[current];

    return (
        <React.Fragment>
            <div className='loc_content'>
            <div onClick={() => setShowModal(true)} className="btn btn-add">{t('add')}</div>
            <h1><div className="goBackBtn" onClick={() => navigate('/manage/organisation/list/all')}><i className="fas fa-arrow-circle-left"></i></div> {CurrentObjectName}</h1>
            

            <div className="alert alert-success alert-add" role="alert">
                {t('neworgitem')}
            </div>

            <div className="alert alert-success alert-delete" role="alert">
                {t('delorgitem')}
            </div>

            <div className="alert alert-danger" role="alert">
                {t('somethingwrong')}
            </div>

            <div className="DataTable_Container">
                <div className="bulk" style={{ marginTop: '20px' }}>
                    <span style={{ color: '#000000', marginRight: '10px' }}>{t('bulkselect')}: </span>
                    <select style={{ marginRight: '10px' }} onChange={(event) => bulkChangeLocation(event, bulk)}>
                        <option value="0">{t('select')}</option>
                        {locations.map((location, i) => {
                            return (
                                <option key={i} value={location.objectid}>{location.objectname}</option>
                            )
                        })}
                    </select>
                    <select style={{ marginRight: '10px' }} onChange={(event) => bulkChangeRoute(event, bulk)}>
                        <option value="0">{t('select')}</option>
                        {routes.map((route, i) => {
                            return (
                                <option key={i} value={route.routeid}>{route.routename}</option>
                            )
                        })}
                    </select>
                    <i onClick={(event) => confirmDelete(t('delitem'), function () { bulkDeleteItems(event, bulk) })} className="btn-delete fas fa-trash-alt"></i>
                </div>
                <div className="table-responsive">
                <table id="DataTable" className="showHead table responsive nowrap" width="100%">
                    <thead>
                        <tr className="rowtitle">
                            <th></th>
                            <th>{t('name')}</th>
                            <th>{t('linkedto')}</th>
                            <th>{t('route')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((organisation, i) => {

                            const mapping = {
                                location: { OrgID: 1, ObjectID: organisation.locationid, ObjectName: organisation.locationname },
                                building: { OrgID: 2, ObjectID: organisation.buildingid, ObjectName: organisation.buildingname },
                                floor: { OrgID: 3, ObjectID: organisation.floorid, ObjectName: organisation.floorname },
                                room: { OrgID: 4, ObjectID: organisation.roomid, ObjectName: organisation.roomname },
                                employee: { OrgID: 5, ObjectID: organisation.employeeid, ObjectName: organisation.employeename },
                                client: { OrgID: 6, ObjectID: organisation.clientid, ObjectName: organisation.clientname },
                                sharedspace: { OrgID: 7, ObjectID: organisation.spaceid, ObjectName: organisation.spacename }
                            };

                            const { OrgID, ObjectID, ObjectName } = mapping[current];

                            return (
                                <tr key={i}>
                                <td style={{ width: '1px' }} data-id={ObjectID}><input type="checkbox" className="bulkSelect" onChange={(event) => bulkSelect(event, bulk)} /></td>
                                <td onClick={(event) => { navigate(`/manage/organisation/details/${ObjectID}/${OrgID}`) }}>{ObjectName}</td>
                                <td>{organisation.breadcrumb ? organisation.breadcrumb : organisation.relatedname}</td>
                                <td>{organisation.routename}</td>
                                <td>{Object === 'location' ? '' : <i onClick={(event) => confirmDelete(t('delitem'), function () { deleteItem(event, ObjectID, ObjectName) })} className="btn-delete fas fa-trash-alt"></i>}</td>
                                </tr>
                            )
                            })}
                    </tbody>
                </table>
                </div>
            </div>
            </div>

            <ModalDelete />

            {showModal && ( <div style={{ display: 'block' }} id="add" className="modal fade in">
                <div className="modal-content ">
                    <div className="modal-header">
                        <h4 className="modal-title">{t('addorgitem')}</h4>
                        <div onClick={() => setShowModal(false)} className="close">&times;</div>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={(event) => addItem(event)}>
                            <div className="row">
                                <div className="col-md-12" style={{ padding: '0' }}>
                                    <div className="form-group">
                                        <label for="name">{t('name')}</label>
                                        <input type="text" name="name" id="name" className="form-control" required />
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ padding: '0' }}>
                                    <div className="form-group">
                                        <label for="related">{t('location')}</label>
                                        <select name="related" id="related" className="form-control">
                                            <option value="0">{t('select')}</option>
                                            {locations.map((location, i) => {
                                                return (<option value={location.objectid}>{location.objectname}</option>)
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ padding: '0' }}>
                                    <div className="form-group">
                                        <label for="route">{t('route')}</label>
                                        <select name="route" id="route" className="form-control" required>
                                            <option value="">{t('select')}</option>
                                            {routes.map((route, i) => {
                                                return (<option value={route.routeid}>{route.routename}</option>)
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6" style={{ padding: '0' }}>
                                    <div onClick={() => setShowModal(false)} id="cancel" className="btn btn-red cancel">{t('cancel')}</div>
                                </div>
                                <div className="col-md-6" style={{ padding: '0' }}>
                                    <button type="submit" className="btn btn-green">{t('add')}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div> )}
        </React.Fragment>
    );
}

export default withRouter(OrganisationItems);