import React, { useEffect, useState } from 'react';
import withAuth from '../../components/Login/withAuth';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import LoadingScreen from '../../components/LoadingScreen';
const $ = require('jquery');

const NightNurse = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [rooms, setRooms] = useState([]);
    const [loadingScreen, setLoadingScreen] = useState(true);

    const getData = async () => {
        setLoadingScreen(true);
        const functions = await import("../../Functions");
        const roomsData = await functions.fetchApi("mba", "/mba/rooms/location", "GET", {
            "locationid": localStorage.getItem('currentLocation')
        });

        const roomsWithDevices = await Promise.all(roomsData.map(async room => {
            const devices = await functions.fetchApi("mba", `/mba/room/device/get/${room.roomid}`, "GET");
            return { ...room, devices };
        }));

        const roomsWithKepler = await Promise.all(roomsWithDevices.map(async room => {
            const deviceConfigs = await Promise.all(room.devices.map(async device => await functions.fetchApi("mba", `/mba/device/config/get/${device.deviceid}`, "GET")));
            const keplerParams = deviceConfigs.flatMap(params => params.filter(param => param.parametername === "KeplerID" && param.parametervalue !== ''));
            room.haskepler = keplerParams.length > 0 ? 1 : 0;
            return room.haskepler ? room : null;
        }));

        const nightnurserooms = roomsWithKepler.filter(Boolean);

        setRooms(nightnurserooms);
        functions.initDataTable();
        setLoadingScreen(false);
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LoadingScreen state={loadingScreen} />

            <div className='loc_content'>
                <h1>{t('nightnurse')}</h1>

                <div className="DataTable_Container">
                <div className="table-responsive">
                    <table id="DataTable" class="table responsive nowrap" width="100%">
                        <thead style={{ display: 'table-header-group', width: '100%' }}>
                            <tr class="rowtitle">
                                <th>{t('room')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rooms.map((room, i) => {
                                return (
                                    <tr style={{ cursor: 'pointer' }} onClick={() => { navigate(`/manage/nightnurse/${room.roomid}`) }}>
                                        <td>{room.roomname}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default withAuth(NightNurse);