import React, { Component, useEffect, useState } from 'react';
import '../../assets/style.css';
import withRouter from '../../components/withRouter';
import { initDataTable, bulkSelect, confirmDelete, crudAlertControl, fetchApi } from "../../Functions.js";
import ModalDelete from "../../components/ModalDelete";
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
const $ = require('jquery');

const Devices = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [devices, setDevices] = useState([]);
    const [locations, setLocations] = useState([]);
    const [type, setType] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const bulk = [];

    const getData = async (init) => {
        const [type, data, objects] = await Promise.all([
            fetchApi("mba", `/mba/devicetype/get/${props.router.params.id}`, "GET"),
            fetchApi("mba", "/mba/device/get", "GET"),
            fetchApi("mba", "/mba/object/get", "GET")
        ]);

        const DeviceArray = data.filter(device => device.devicetypeid === parseInt(props.router.params.id));
        const newDevicesArray = await Promise.all(DeviceArray.map(async(device) => { 
            const params = await fetchApi("mba", `/mba/device/config/get/${device.deviceid}`, "GET");
            device.params = params; return device; 
        }));

        setType(type[0]);
        setDevices(newDevicesArray);
        setLocations(objects);
        initDataTable();
    }

    const addDevice = async (event) => {
        event.preventDefault();
        const data = await fetchApi("mba", "/mba/device/insert/", "GET", {
            "name": event.target.name.value, "devicetypeid": props.router.params.id, "locationid": event.target.location.value,
        });

        if(data.length > 0 && data[0] && data[0].deviceid && event.target.location.value){
            const mbasettings = await fetchApi("mba", "/mba/settings/mba-templates", "GET");

            if(mbasettings[0].value === '1'){
                const rule = await fetchApi("mba", "/mba/admin/responserule/template/", "GET", {
                    "deviceid": data[0].deviceid, "devicetypeid": props.router.params.id, "objectid": event.target.location.value,
                });
            }
        }
        
        getData();
        crudAlertControl('add', data);
        setShowModal(false);
    }

    const delDevice = async (objectID, devicename) => {
        const [data, mbasettings] = await Promise.all([
            fetchApi("mba", `/mba/device/delete/${objectID}`, "GET", {
                "deviceid": objectID, "name": devicename
            }),
            fetchApi("mba", "/mba/settings/mba-templates", "GET")
        ])

        if(data === "OK" && mbasettings[0].value === '1'){
            const rule = await fetchApi("mba", "/mba/admin/responserule/device/delete", "GET", {
                "deviceid": objectID,
            });
        }
        
        getData();
        crudAlertControl('delete', data);
    }

    const bulkDeleteDevice = async (event, array) => {
        array.forEach(async (device) => {
            const data = delDevice(device.id, device.name)
            getData();
            crudAlertControl('delete', data);
        })
    }

    const bulkChangeLocation = async (event, array) => {
        array.forEach(async (device) => {
            const data = await fetchApi("mba", `/mba/device/update/${device.id}/location`, "GET", {
                "deviceid": device.id,
                "locationid": event.target.value
            });
            getData();
            crudAlertControl('update', data);
        })
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <div className='loc_content'>
                <div onClick={() => setShowModal(true)} className="btn btn-add">{t('add')}</div>
                <h1><button className="goBackBtn" onClick={() => navigate('/manage/devices/list/all')}><i class="fas fa-arrow-circle-left"></i></button> {type.devicetypename}</h1>
                

                <div className="alert alert-success alert-add" role="alert">
                    {t('newdevice')}
                </div>

                <div className="alert alert-success alert-delete" role="alert">
                    {t('deldevice')}
                </div>

                <div class="alert alert-danger" role="alert">
                    {t('somethingwrong')}
                </div>

                <div className="DataTable_Container">
                    <div class="bulk" style={{ marginTop: '20px' }}>
                        <span style={{ color: '#000000', marginRight: '10px' }}>{t('bulkselect')}: </span>
                        <select style={{ marginRight: '10px' }} onChange={(event) => bulkChangeLocation(event, bulk)}>
                            <option value="0">{t('select')}</option>
                                {locations.map((location, i) => {
                                    return (
                                        <option value={location.objectid}>{location.objectname}</option>
                                        )
                                })}
                        </select>
                        <i onClick={(event) => confirmDelete(t('delitem'), function () { bulkDeleteDevice(event, bulk) })} className="btn-delete fas fa-trash-alt"></i>
                    </div>
                    <div className="table-responsive">
                    <table id="DataTable" className="showHead table responsive nowrap" width="100%">
                        <thead>
                            <tr className="rowtitle">
                                <th></th>
                                <th>{t('name')}</th>
                                <th>{t('type')}</th>
                                <th>{t('location')}</th>
                                <th style={{ display:'none' }}>params</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {devices.map((device, i) => {
                                return (
                                    <tr key={i}>
                                        <td style={{ width: '1px' }} data-id={device.deviceid} data-name={device.devicename}><input type="checkbox" className="bulkSelect" onChange={(event) => bulkSelect(event, bulk)} /></td>
                                        <td onClick={(event) => { navigate(`/manage/device/${device.deviceid}`) }}>{device.devicename}</td>
                                        <td>{device.devicetype}</td>
                                        <td>{device.locationname}</td>
                                        <td style={{ display:'none' }}>
                                        {device.params.map(param => {
                                            return(<div>{param.parametervalue}</div>);
                                        })}
                                        </td>
                                        <td><i onClick={(event) => confirmDelete(t('delitem'), function () { delDevice(device.deviceid, device.devicename) })} className="btn-delete fas fa-trash-alt"></i></td>
                                    </tr>
                                    )
                            })}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>

            <ModalDelete />  

            {showModal && ( <div style={{ display: 'block' }} id="add" className="modal fade in">
                <div className="modal-content ">
                    <div className="modal-header">
                        <h4 className="modal-title">{t('adddevice')}</h4>
                        <div onClick={() => setShowModal(false)} className="close">&times;</div>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={(event) => addDevice(event)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="idname">{t('name')}</label>
                                        <input type="text" name="name" id="name" className="form-control" required />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="location">{t('location')}</label>
                                        <select name="Location" id="location" className="form-control" required>
                                            <option value="">{t('select')}</option>
                                            {locations.map((location, i) => {
                                                return (<option value={location.objectid}>{location.objectname}</option>)
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div onClick={() => setShowModal(false)} id="cancel" className="btn btn-red cancel">{t('cancel')}</div>
                                </div>
                                <div className="col-md-6">
                                    <button type="submit" className="btn btn-green">{t('add')}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div> )}
        </React.Fragment>
        );
}

export default withRouter(Devices);
