import React, { Component, useEffect, useState } from 'react';
import '../../assets/style.css';
import withAuth from '../../components/Login/withAuth.js';
import * as moment from 'moment';
import { initDataTable, bulkSelect, confirmDelete, crudAlertControl, fetchApi } from "../../Functions.js";
import { useNavigate } from 'react-router';
import ModalDelete from "../../components/ModalDelete";
import { useTranslation } from 'react-i18next';
moment.locale('nl');

const WelfareSettings = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [settings, setSettings] = useState([]);
    const [types, setTypes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const bulk = [];

    const addAlert = async (event) => {
        event.preventDefault();
        const data = await fetchApi("mba", "/mba/welfare/alarms/add", "GET", {
          "name": event.target.name.value, "locationid": localStorage.getItem('currentLocation'), "type": event.target.type.value, "time": event.target.type.value == 2 ? "0:01" : null
        });

        if(data && data.length > 0){
          navigate('/manage/welfaresettings/' + data[0].id);
        }
    }

    const alarmSwitch = async (event, AlarmID, alarmname) => {
        const type = event.target.checked ? 'activate' : 'deactivate';
        await fetchApi("mba", `/mba/welfare/alarms/${AlarmID}/${type}`, "GET", {
          "alarmid": AlarmID, "alarmname": alarmname
        });
    }

    const deleteAlert = async (row, objectID, alarmname) => {
        const data = await fetchApi("mba", `/mba/welfare/alarms/${objectID}/delete`, "GET", {
          "welfarealarmid": objectID, "name": alarmname
        });
        getData();
        crudAlertControl('delete', data);
    }

    const bulkDeleteAlert = async (event, array) => {
        array.forEach(async (alarm) => {
          const data = await fetchApi("mba", `/mba/welfare/alarms/${alarm}/delete`, "GET", {
            "welfarealarmid": alarm, "name": ''
          });
          getData();
        })
    }

    const getData = async (init) => {
        const [welfareData, welfareTypes] = await Promise.all([
          fetchApi("mba", "/mba/welfare/alarms", "GET", { "locationid": localStorage.getItem('currentLocation') }),
          fetchApi("mba", "/mba/welfare/types", "GET")
        ])
        setSettings(welfareData);
        setTypes(welfareTypes);
        initDataTable();
    }

    useEffect(() => {
        getData();
    }, [])

  return (
    <React.Fragment>
      <div className='loc_content'>
        <div onClick={() => setShowModal(true)} class="btn btn-add">{t('add')}</div>
        <h1>{t('welfaremanagement')}</h1>

        <div className="alert alert-success alert-add" role="alert">
            {t('newalert')}
        </div>

        <div className="alert alert-success alert-delete" role="alert">
            {t('delalert')}
        </div>

        <div class="alert alert-danger" role="alert">
            {t('somethingwrong')}
        </div>

        <div className="DataTable_Container">
          <div class="bulk" style={{ marginTop: '20px' }}>
            <span style={{ color: '#000000', marginRight: '10px' }}>{t('bulkselect')}: </span>
            <i onClick={(event) => confirmDelete(function () { bulkDeleteAlert(event, bulk) })} className="btn-delete fas fa-trash-alt"></i>
          </div>
          <div className="table-responsive">
          <table id="DataTable" class="showHead table responsive nowrap" width="100%">
            <thead>
              <tr class="rowtitle">
                <th></th>
                <th>{t('alert')}</th>
                <th>{t('type')}</th>
                <th>{t('createdon')}</th>
                <th>{t('timealert')}</th>
                <th>{t('active')}</th>
                <th>{t('delete')}</th>
              </tr>
            </thead>
            <tbody>
              {settings.map((alarm, i) => {
                var checked = alarm.active === 1 ? true : false;

                return (
                  <tr key={i}>
                    <td style={{ width: '1px' }} data-id={alarm.id}><input type="checkbox" className="bulkSelect" onChange={(event) => bulkSelect(event, bulk)} /></td>
                    <td onClick={(event) => { navigate(`/manage/welfaresettings/${alarm.id}`) }}>{alarm.name}</td>
                    <td>{alarm.type}</td>
                    <td>{moment(alarm.datecreated).format('DD-MM-YYYY HH:mm')}</td>
                    <td>{alarm.datealert}</td>
                    <td>
                      <label class='switch'>
                        <input id={`Welfarealarm_${alarm.id}`} onClick={(event) => { alarmSwitch(event, alarm.id, alarm.name) }} class='WelfareAlarmtogBtn' type='checkbox' defaultChecked={checked} />
                        <span class='slider round'></span>
                      </label>
                    </td>
                    <td><i onClick={(event) => confirmDelete(t('delitem'), function () { deleteAlert(event, alarm.id, alarm.name) })} class="btn-delete fas fa-trash-alt"></i></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          </div>
        </div>
      </div>

      <ModalDelete />

      {showModal && ( <div style={{ display: 'block' }} id="add" class="modal modal-add fade in">
        <div class="modal-content ">
          <div class="modal-header">
            <h4 class="modal-title">{t('addalert')}</h4>
            <div onClick={() => setShowModal(false)} class="close">&times;</div>
          </div>
          <form onSubmit={(event) => addAlert(event)}>
            <div class="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div class="form-group">
                    <label for="teamname">{t('name')}</label>
                    <input type="text" name="name" id="name" class="form-control" required />
                  </div>
                  <div class="form-group">
                    <label for="type">{t('type')}</label>
                    <select name="type" id="type" className="form-control" required>
                    <option value="0">{t('select')}</option>
                      {types.map(type => {
                        return(
                          <option value={type.id}>{type.type}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div onClick={() => setShowModal(false)} id="cancel" class="btn btn-red cancel">{t('cancel')}</div>
                </div>
                <div class="col-md-6">
                  <button type="submit" className="btn btn-green">{t('add')}</button>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div> )}
    </React.Fragment>
  );
}

export default withAuth(WelfareSettings);
