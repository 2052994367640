import React, { Component } from 'react';
import '../../assets/style.css';
import withAuth from '../../components/Login/withAuth';
import { initDataTable, bulkSelect, confirmDelete, fetchApi } from "../../Functions.js";
const $ = require('jquery');
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/disable-selection');

class VirtualTourSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cams: [],
      rooms: []
    }
    this.bulk = []
  }

  componentDidMount() {

    fetchApi("mba", "/mba/virtualtour/cams", "GET").then(data => {
      this.setState({
        cams: data
      });

      initDataTable();
    })

    fetchApi("mba", "/mba/virtualtour/rooms", "GET").then(rooms => {
      this.setState({
        rooms: rooms
      });
    })
  }

  addRoom(event) {
    event.preventDefault();
    fetchApi("mba", "/mba/virtualtour/insert", "GET", {
        "roomid": event.target.room.value,
    }).then(rooms => {
      window.location.reload();
    })
  }

  deleteRoom(objectID) {
    fetchApi("mba", `/mba/virtualtour/delete/${objectID}`, "GET", {
      "tourid": objectID,
    }).then(rooms => {
      window.location.reload();
    })
  }

  bulkDeleteRoom(array) {
    array.forEach(function (room) {
      fetchApi("mba", `/mba/virtualtour/delete/${room}`, "GET", {
        "tourid": room,
      }).then(rooms => {
        window.location.reload();
      })
    })
  }

  sortCam(tourid, sort) {
    fetchApi("mba", `/mba/virtualtour/cam/${tourid}/sort`, "GET", {
      "tourid": tourid, "sort": sort
    }).then(card => {
      window.location.reload();
    })
  }

  render() {

    var $this = this;

    $(function () {
      $(".sortTable tbody").sortable({
        cursor: "move",
        placeholder: "sortable-placeholder",
        helper: function (e, tr) {
          var $originals = tr.children();
          var $helper = tr.clone();
          $helper.children().each(function (index) {
            $(this).width($originals.eq(index).width());
          });
          return $helper;
        },
        stop: function (event, ui) {
          var tourid = ui.item[0].dataset.tourid;
          var sort = ui.item.index();

          $this.sortCam(tourid, sort);
        }
      }).disableSelection();
    });

    $('.btn-add').on('click', function () {
      $('.modal').show();
    });

    $('.modal .close, .modal .cancel').on('click', function () {
      $('.modal').hide();
    });

    return (
      <React.Fragment>
        <h2>Beheer virtuele tour</h2>

        <div class="btn btn-add">Toevoegen</div>
        <div className="DataTable_Container">
          <div class="bulk" style={{ marginTop: '20px' }}>
            <span style={{ color: '#000000', marginRight: '10px' }}>Geselecteerde items: </span>
            <i onClick={(event) => confirmDelete(function () { $this.bulkDeleteRoom(event, $this.bulk) })} className="btn-delete fas fa-trash-alt"></i>
          </div>
          <div className="table-responsive">
          <table id="DataTable" class="showHead table responsive nowrap tourTable sortTable" data-sort="false" data-state="false" width="100%">
            <thead>
              <tr class="rowtitle">
                <th></th>
                <th>Kamer/appartement</th>
                <th>Camera</th>
                <th>Host</th>
                <th>RTSP</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.cams.map((tour, i) => {

                var checked = false;

                if (tour.rtsp === 1) {
                  checked = true;
                }

                return (
                  <tr data-tourid={tour.id}>
                    <td style={{ width: '1px' }} data-id={tour.id}><input type="checkbox" className="bulkSelect" onChange={(event) => bulkSelect(event, this.bulk)} /></td>
                    <td>{tour.room}</td>
                    <td>{tour.device}</td>
                    <td>{tour.value}</td>
                    <td>
                      <label className='switch'>
                        <input key={`${Math.floor((Math.random() * 1000))}-min`} defaultChecked={checked} onChange={(event) => this.switchRTSP(event, tour.id)} type='checkbox' />
                        <span className='slider round'></span></label>
                    </td>
                    <td style={{ textAlign: 'right', paddingRight: '20px' }}>
                      <i onClick={(event) => confirmDelete(function () { $this.deleteRoom(tour.id) })} class="btn-delete fas fa-trash-alt"></i>
                    </td>
                  </tr>)
              })}
            </tbody>
          </table>
          </div>
        </div>

        <div className="modal" id='modal_dialog'>
          <div className="modal-content" style={{ textAlign: 'center' }}>
            <div className="modal-header">
              <h4 className="modal-title">Verwijderen</h4>
              <div className="close">&times;</div>
            </div>
            <div className="modal-body">
              <i class="fas fa-exclamation-circle"></i>
              <div class='modalTitle'></div>

              <div className="row">
                <div className="col-md-6" style={{ padding: '0' }}>
                  <button id="cancel btnNo" className="btn btn-red cancel">Annuleren</button>
                </div>
                <div className="col-md-6" style={{ padding: '0' }}>
                  <button id='btnYes' className="btn btn-green">Verwijderen</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="add" class="modal fade in">
          <div class="modal-content ">
            <div class="modal-header">
              <h4 class="modal-title">Kamer toevoegen</h4>
              <div class="close">&times;</div>
            </div>
            <div class="modal-body">
              <form onSubmit={(event) => this.addRoom(event)}>
                <div className="row">
                  <div className="col-md-12" style={{ padding: '0' }}>
                    <div class="form-group">
                      <label for="teams-location">Kamer/appartement</label>
                      <select name="room" id="room" class="form-control" required>
                        <option value="">Selecteer kamer/appartement</option>
                        {this.state.rooms.map((room, i) => {
                          return (<option value={room.id}>{room.room}</option>)
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6" style={{ padding: '0' }}>
                    <div id="cancel" class="btn btn-red cancel">Annuleren</div>
                  </div>
                  <div class="col-md-6" style={{ padding: '0' }}>
                    <button type="submit" className="btn btn-green">Toevoegen</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withAuth(VirtualTourSettings);
