import React, { useState, useEffect } from 'react';
import withAuth from '../../components/Login/withAuth';
import Menu from '../../components/MbaMenu.js';
import { CutOnLength, checkMBAUserPermission, confirmDelete, confirmDuplicate, crudAlertControl, initDataTable } from './Functions';
import { filterDataTable, fetchApi } from '../../Functions';
import ModalDelete from '../../components/MbaModalDelete.js';
import { useNavigate } from 'react-router';
const $ = require('jquery');

const Messages = () => {
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [connections, setConnections] = useState([]);
    const [types, setTypes] = useState([]);
    const [descriptions, setDescriptions] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const getData = async () => {
        const [conndata, types, descriptions, data] = await Promise.all([
            await fetchApi("mba", "/mba/admin/responserule/connections/", "GET"),
            await fetchApi("mba", "/mba/admin/messages/types/get", "GET"),
            await fetchApi("mba", "/mba/admin/messages/types/get", "GET"),
            await fetchApi("mba", "/mba/admin/messages/get", "GET")
        ])
        setConnections(conndata);
        setDescriptions(descriptions);
        setTypes(types);
        setMessages(data);
        initDataTable();
    }

    const addMsg = async (event) => {
        event.preventDefault();
        var msgname = event.target.msgname.value;
        var msgconnection = event.target.connection.value;
        var msgtype = event.target.type.value;
        var msgdesc = event.target.description.value;
        var msgcontent = event.target.msgcontent.value ? event.target.msgcontent.value : null;
        var msgincoming = event.target.incoming.checked ? 1 : 0;
        var msgoutgoing = event.target.outgoing.checked ? 1 : 0;
        var msgreport = event.target.report.checked ? 1 : 0;

        const data = await fetchApi("mba", "/mba/admin/message/insert", "GET", {
            "name": msgname, "content": msgcontent, "connectionid": msgconnection, "msgtypeid": msgtype, "msgdescid": msgdesc, "incoming": msgincoming, "outgoing": msgoutgoing, "report": msgreport
        });
        crudAlertControl('add', data);
        setShowModal(false);
        await getData();
    }

    const copyMessage = async (msgid) => {
        const data = await fetchApi("mba", `/mba/admin/messages/${msgid}/copy`, "GET", {
            "msgid": msgid
        });
        crudAlertControl('duplicate', data);
        await getData();
    }

    const deleteMessage = async (msgid) => {
        const data = await fetchApi("mba", `/mba/admin/messages/${msgid}/delete`, "GET", {
            "msgid": msgid
        });
        crudAlertControl('delete', data);
        await getData();
    }

    useEffect(() => {
        checkMBAUserPermission();
        getData();
    }, [])

    return(
        <React.Fragment>
            <Menu />

            <div className="alert alert-success alert-dup" role="alert">
                Message gedupliceerd.
            </div>

            <div className="alert alert-success alert-add" role="alert">
                Message aangemaakt.
            </div>

            <div className="alert alert-success alert-delete" role="alert">
                Message verwijderd.
            </div>

            <div className="alert alert-danger" role="alert">
                Er is iets niet goed gegaan. Probeer het later nog eens.
            </div>

            <h2>Messages</h2>
            <div className="btn btn-add" onClick={() => setShowModal(true)}>Toevoegen</div>
            <div style={{float: 'right', marginTop: '-22px', marginRight: '30px', color: '#333'}}>
                <select onChange={(event) => filterDataTable('mba-message-type', 3, event.target.value, false)} id="timetable" key={`${Math.floor((Math.random() * 1000))}-min`} name="timetable" className='form-control'>
                    <option value="">Selecteer connection</option>
                    {connections.map((connection, i) => {
                        return <option key={i} value={connection.name}>{connection.name}</option>
                    })}
                </select>
            </div>
                {/* <Filter type="select" text="Type" id="msgtype" column="3" item="mba-message-type" exact={false} /></div> */}

            <div className="DataTable_Container">
                <table id="DataTable" class="showHead table responsive nowrap" width="100%">
                    <thead>
                    <tr class="rowtitle">
                        <th>In/out</th>
                        <th>Name</th>
                        <th>Content</th>
                        <th>Connection</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {messages.map((message, i) => {
                        return (
                        <tr key={i}>
                            <td onClick={() => navigate("/mba-admin/messages/" + message.id)}>{message.outgoing == 1 ? 'Outgoing' : 'Incoming' }</td>
                            <td onClick={() => navigate("/mba-admin/messages/" + message.id)}>{message.name}</td>
                            <td onClick={() => navigate("/mba-admin/messages/" + message.id)}>{message.content ? CutOnLength(message.content, 30) : ''}</td>
                            <td onClick={() => navigate("/mba-admin/messages/" + message.id)}>{message.connectionname}</td>
                            <td>
                                <i onClick={() => confirmDuplicate(function () { copyMessage(message.id) })} class="fas fa-copy" style={{ marginRight:'10px' }}></i>
                                <i onClick={() => confirmDelete(function () { deleteMessage(message.id) })} style={{ color: '#ff0000', marginTop: '3px', cursor: 'pointer' }} class="fas fa-trash-alt"></i></td>
                        </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>

            <ModalDelete />

            {showModal && ( 
            <div style={{ display: 'block' }} id="add" className="modal fade in">
                <div className="modal-content ">
                    <div className="modal-header">
                        <h4 className="modal-title">Message toevoegen</h4>
                        <div onClick={() => setShowModal(false)} className="close">&times;</div>
                    </div>

                    <div className="modal-body">
                        <form onSubmit={(event) => addMsg(event)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="msgname">Naam</label>
                                        <input type="text" name="msgname" id="msgname" className="form-control" required />
                                    </div>
                                    <div className="form-group">
                                        <label for="connection">Connection</label>
                                        <select id="connection" key={`${Math.floor((Math.random() * 1000))}-min`} name="connection" className='form-control'>
                                            <option value="0">Selecteer connection</option>
                                            {connections.map((connection, i) => {
                                                return <option key={i} value={connection.id}>{connection.name}</option>;
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label for="type">MessageType</label>
                                        <select id="type" key={`${Math.floor((Math.random() * 1000))}-min`} name="type" className='form-control'>
                                            <option value="0">Selecteer type</option>
                                            {types.map((type, i) => {
                                                return <option key={i} value={type.id}>{type.name}</option>;
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label for="msgcontent">Content</label>
                                        <textarea name="msgcontent" id="msgcontent" className="form-control"></textarea>
                                    </div>

                                    <div className="form-group">
                                        <label for="description">Message description</label>
                                        <select id="description" key={`${Math.floor((Math.random() * 1000))}-min`} name="description" className='form-control'>
                                            <option value="0">Selecteer description</option>
                                            {descriptions.map((desc, i) => {
                                                return <option key={i} value={desc.id}>{desc.name}</option>;
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label for="incoming">Incoming message</label>
                                        <label className='switch'>
                                            <input key={`${Math.floor((Math.random() * 1000))}-min`} name="incoming" id="incoming" type='checkbox' />
                                            <span className='slider round'></span>
                                        </label>
                                    </div>
                                    <div className="form-group">
                                        <label for="outgoing">Outgoing message</label>
                                        <label className='switch'>
                                            <input key={`${Math.floor((Math.random() * 1000))}-min`} name="outgoing" id="outgoing" type='checkbox' />
                                            <span className='slider round'></span>
                                        </label>
                                    </div>
                                    <div className="form-group">
                                        <label for="report">Report message</label>
                                        <label className='switch'>
                                            <input key={`${Math.floor((Math.random() * 1000))}-min`} name="report" id="report" type='checkbox' />
                                            <span className='slider round'></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div onClick={() => setShowModal(false)} id="cancel" className="btn btn-red cancel">Annuleren</div>
                                </div>
                                <div className="col-md-6">
                                    <button type="submit" className="btn btn-green">Toevoegen</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div> )}
        </React.Fragment>
    )
}

export default withAuth(Messages);