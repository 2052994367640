import React, { useEffect, useState } from 'react';
import '../../../assets/style.css';
import withAuth from '../../../components/Login/withAuth';
import * as moment from 'moment';
import LocationAlert from '../../../components/LocationAlert';
import { useTranslation } from 'react-i18next';
moment.locale('nl');

const VirtualRound = (props) => {
    const { t } = useTranslation();
    const [rooms, setRooms] = useState([]);
    
    const getData = async () => {
        const functions =  await import("../../../Functions.js");

        const getStatusTime = (datetimeQueue) => {
            var date_in_queue = moment(datetimeQueue);
            var date_now = moment();
            return moment.utc(moment(date_now,"DD/MM/YYYY HH:mm:ss").diff(moment(date_in_queue,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss");
        }

        const detectionsArray = [
            { label: "NO_DETECTION", text: t('nodetection'), icon: ''},
            { label: "MAN_DOWN_DETECTION", text: t('falling'), icon: 'falling.png' },
            { label: "SOEB_DETECTION", text: t('soeb'), icon: 'soeb.png' },
            { label: "OUT_OF_BED_DETECTION", text: t('outofbed'), icon: 'outofbed.png' },
            { label: "OUT_OF_ROOM_DETECTION", text: t('outofroom'), icon: 'outofroom.png' },
            { label: "IN_BED_DETECTION", text: t('inbed'), icon: 'inbed.png' },
            { label: "IN_BATHROOM_DETECTION", text: t('inbathroom'), icon: 'inbathroom.png' },
            { label: "MISSING_BED_DETECTION", text: t('nobed'), icon: 'nobed.png' },
            { label: "PERSON_VISIBLE_DETECTION", text: t('personvisible'), icon: '' },
            { label: "PERSON_GETTING_UP_FROM_CHAIR_DETECTION", text: t('standingfromchair'), icon: 'standingfromchair.png' },
            { label: "PERSON_GETTING_UP_IN_BED_DETECTION", text: t('sittinginbed'), icon: 'sittinginbed.png' },
            { label: "STAFF_ENTERING_ROOM_DETECTION", text: t('employee'), icon: 'staff.png' },
            { label: "CAMERA_UNREACHABLE_DETECTION" ,text: t('cameraunreachable'),  icon: 'camera-unreachable.png' },
            { label: "HALLWAY_WANDER_DETECTION", text: t('wandering'), icon: 'wandering.png' }
        ];
        
        const roomstatus = await functions.fetchApi("kepler", "/nightnurse/room/status", "GET", {
            "locationid": localStorage.getItem('currentLocation')
        });

        if(roomstatus.length > 0){
            const keplerrooms = await Promise.all(roomstatus.map(async (room) => {
                const item = {
                    room: room.object,
                    alarms: await functions.fetchApi("mba", `/mba/object/alarms/get/${room.objectid}`, "GET", {
                        "objectid": room.objectid,
                    }),
                    status: room.state
                };
        
                const currentStatus = detectionsArray.find(type => type.label === room.state);
                const currentStatusTime = getStatusTime(room ? moment(room.time).utcOffset("+0000").format("YYYY-MM-DD HH:mm:ss") : moment().format("YYYY-MM-DD HH:mm:ss"));
        
                return {
                    roomname: item.room,
                    status: currentStatus ? currentStatus.text : t('nostatus'),
                    icon: currentStatus ? currentStatus.icon : '',
                    duration: currentStatusTime,
                    critical: item.alarms,
                    alarmsred: item.alarms && item.alarms[0] && item.alarms[0].downtriggered === null && item.alarms[0].finishtriggered === null ? 1 : 0,
                    alarmsorange: item.alarms && item.alarms[0] && item.alarms[0].downtriggered !== null && item.alarms[0].finishtriggered === null ? 1 : 0
                };
            }));
    
            setRooms(keplerrooms);
        }
    }
    
    useEffect(() => {
        getData();
        const interval = setInterval(() => getData(), 5000)
        return () => clearInterval(interval)
    }, [])
    
    return(
        <React.Fragment>
            <LocationAlert />

            {!rooms || rooms && rooms.length === 0 ? <div className="loadingScreen"><div className="loader"></div></div> : ''}

            <div className='loc_content'>
                    <h1>{t('virtualround')}</h1>
                    <div className='row' style={{ padding: '20px 25px' }}>
                        {rooms ? rooms.map(room => {
                            const alarmcolor = room.alarmsred > 0 ? '#ff0000' : (room.alarmsorange > 0 ? '#FFA500' : 'rgb(92, 184, 92)');

                            return(
                                <div className='col-md-4'>
                                    <div class="card round-card" style={{ borderLeft: "solid 5px " + alarmcolor }}>
                                        <h4 className='room' style={{ padding: "10px 15px", textAlign: 'center', fontSize: '18px', fontWeight: '500' }}>{room.roomname}</h4>
                                        {room.icon ? <img src={`/images/icons/kepler/${room.icon}`} style={{ height: '100px', width:'auto', margin: 'auto', display: 'block' }}/> : <i className='fa fa-close noStatus'></i>}
                                        <div className='statustext' style={{ padding: "10px 15px 0 15px", fontSize: '16px', textAlign: 'center', marginTop: '20px', fontWeight: '400' }}><span>{room.status}</span></div>
                                        <div className='time' style={{ padding: "0px 15px 10px 15px", fontSize: '14px', textAlign: 'center', marginTop: '5px' }}><span>{room.duration}</span></div>
                                        <div className='criticalalert' style={{ padding: "10px 0px", textAlign: 'center', fontWeight: '300'}}>
                                            <span class="criticalalarms" style={{ fontSize: '16px', marginRight: "10px" }}><i style={{ color: room && room.critical ? (room.critical.length > 0 ? "#ff0000" : "rgb(92, 184, 92)") : "rgb(92, 184, 92)" }} className={ room && room.critical ? (room.critical.length > 0 ? 'fas fa-exclamation-triangle' : 'fa-solid fa-check') : 'fa-solid fa-check'}></i></span>
                                            {room && room.critical ? (room.critical.length > 0 ? t('lastcriticalincident') + ': ' + room.critical[0].status + ' - ' + moment(room.critical[0].uptriggered).format("DD-MM-YYYY HH:mm") : t('nocriticalincident48hours')) : t('nocriticalincident48hours')}
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : ''}
                    </div>
                </div>
        </React.Fragment>
    )
}

export default withAuth(VirtualRound)