import React, { useEffect } from 'react';
import '../../../assets/style.css';
import * as moment from 'moment';
import { SvelteGantt, SvelteGanttTable, MomentSvelteGanttDateAdapter, SvelteGanttDependencies } from 'svelte-gantt';
import LocationAlert from "../../../components/LocationAlert.js";
import withRouter from '../../../components/withRouter';
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from 'date-fns/locale/nl';
import { useNavigate } from 'react-router';
import { checkRefreshToken, getToken, nightnurse_token_check, fetchApi } from '../../../Functions';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { RoomSelector } from '../../../components/RoomSelector.js';
registerLocale('nl', nl);
moment.locale('nl');

const ActivityReport = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loadingScreen, setLoadingScreen] = useState(true);
    var alarmcount = 0;
    var currentdate = new Date(props.router.params.reportdate);
    const [showRoomSelector, setShowRoomSelector] = useState(false);

    const showLoadingScreen = () => {
        setLoadingScreen(true);
    }

    const hideLoadingScreen = () => {
        setLoadingScreen(false);
    }

    const findKeplerStreamName = async (kepleruuid, nameonly = false) => {
        const keplerstream = await fetchApi("kepler", "/nightnurse/stream/id", "GET", {
            "streamid": kepleruuid
        });

        return nameonly == true ? keplerstream.name : keplerstream
    }

    const getStreams = async(rooms) =>{
        const streamsarray = [];

        rooms.forEach(room => {
            const children = room.children;
            children.forEach(stream => {
                streamsarray.push(stream);
            })
        })
        const keplerstreams = await Promise.all(streamsarray.map(async stream => (await findKeplerStreamName(stream.id))));
        return keplerstreams;
    }

    const getAlarmsFromRoom = async (streamid, streamname, reportdate) => {
        const detectionsArray = [
            { id: 0, label: "NO_DETECTION" },
            { id: 1, label: "MAN_DOWN_DETECTION" },
            { id: 2, label: "SOEB_DETECTION" },
            { id: 3, label: "OUT_OF_BED_DETECTION" },
            { id: 4, label: "OUT_OF_ROOM_DETECTION" },
            { id: 5, label: "IN_BED_DETECTION" },
            { id: 6, label: "IN_BATHROOM_DETECTION" },
            { id: 7, label: "MISSING_BED_DETECTION" },
            { id: 8, label: "PERSON_VISIBLE_DETECTION" },
            { id: 9, label: "PERSON_GETTING_UP_FROM_CHAIR_DETECTION" },
            { id: 10, label: "PERSON_GETTING_UP_IN_BED_DETECTION" },
            { id: 11, label: "STAFF_ENTERING_ROOM_DETECTION" },
            { id: 12, label: "CAMERA_UNREACHABLE_DETECTION" },
            { id: 13, label: "HALLWAY_WANDER_DETECTION" }
        ];

        const data = await fetchApi("kepler", "/nightnurse/stream/activities", "GET", {
            "streamid": streamid,
            "reportdate": reportdate
        });

        if (data.camera !== streamname) {
            return [];
        }

        function convertTime(timestamp) {
            var date = timestamp.split(":")[0];
            var datetime = timestamp.split(":")[1];

            var hour = datetime.split("-")[0];
            var minutes = datetime.split("-")[1];

            return date + ' ' + hour + ":" + minutes;
        }

        var previousDate = '';
        var tasksarray = [];


        data.time_entries.map((task, index) => {
            task.Time = convertTime(task.Time);
            var nextDate = convertTime(data.time_entries[1].Time);

            if(!task.Time){
                return [];
            }

            task.Time = moment(new Date(task.Time)).format("YYYY-MM-DD HH:mm");
            previousDate = moment(new Date(previousDate)).format("YYYY-MM-DD HH:mm");
            nextDate = moment(new Date(nextDate)).format("YYYY-MM-DD HH:mm");

            alarmcount += 1;

            tasksarray.push({
                id: alarmcount,
                resourceId: streamid,
                label: detectionsArray.find((alarm) => alarm.id === task.DetectionType)?.label ?? '',
                from: index == 0 ? moment(task.Time) : moment(previousDate),
                to: index == 0 ? moment(nextDate) : moment(task.Time),
                classes: 'ALARM_' + detectionsArray.find((alarm) => alarm.id === task.DetectionType)?.label ?? ''
            });

            previousDate = task.Time
        });

        return tasksarray;
    }

    

    const getRooms = async() => {
        const selectedrooms = JSON.parse(localStorage.getItem('groups'));

        const roomsOfFloor = async (floorid) => {
            return await fetchApi("mba", `/mba/settings/floors/${floorid}/rooms`, "GET", {
                "floorid": floorid
            })
        } 

        const getRoom = async (roomid) => {
            return await fetchApi("mba", `/mba/room/get/${roomid}`, "GET");
        } 

        const roomsarray = await Promise.all(
            (selectedrooms && selectedrooms.length > 0) ? 
              selectedrooms.map(async room => {
                const data = room.type === 'floor' ? await roomsOfFloor(room.id) : await getRoom(room.id);
                return data.map(roomData => ({
                  roomid: room.type === 'floor' ? roomData.objectid : roomData.roomid,
                  roomname: room.type === 'floor' ? roomData.name : roomData.roomname
                }));
              }).flat().filter(Boolean) 
            : []
          );

          var combinedArray = [].concat(...roomsarray);
          const uniquerooms = combinedArray.filter((obj, index, self) => index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(obj)));

        const roomsWithKepler = await Promise.all(
            uniquerooms.map(async (room) => {
                const devices = await fetchApi("mba", "/nightnurse/devices/", "GET", {
                    "roomid": room.roomid
                });
                
                const newdevices = await Promise.all(devices.map(async device => {
                    device.keplername = await findKeplerStreamName(device.kepler, true);
                    return device;
                }));

                return devices.length > 0 ? { id: room.roomid, label: room.roomname, children: newdevices.map(device => ({ id: device.kepler, label: device.keplername, name: device.keplername })) } : null;
            })
        );
      
        const filteredRooms = roomsWithKepler.filter(room => room !== null);
        filteredRooms.sort((a,b) => a.id - b.id);
        return filteredRooms;
      }

    const loadChart = (date, getRooms, getActivities) => {
        var currentStart = moment(date + " 00:00:00");
        var currentEnd = moment(date + " 23:59:59");

        const data = {
            rows: getRooms,
            tasks: getActivities,
            dependencies: []
        };

        let options = {
            dateAdapter: new MomentSvelteGanttDateAdapter(moment),
            rows: data.rows,
            tasks: data.tasks,
            dependencies: data.dependencies,
            timeRanges: [],
            columnUnit: "hour",
            columnOffset: 1,
            magnetUnit: "hour",
            magnetOffset: 1,
            rowHeight: 52,
            rowPadding: 6,
            headers: [
                { unit: "hour", format: "H", sticky: true }
            ],
            fitWidth: true,
            from: currentStart,
            to: currentEnd,
            tableHeaders: [
                { title: t('activityreports'), property: "label", width: 140, type: "tree" }
            ],
            tableWidth: 240,
            ganttTableModules: [SvelteGanttTable],
            ganttBodyModules: [SvelteGanttDependencies]
        };

        new SvelteGantt({
            target: document.getElementById('chart'),
            props: options
        });
    }

    const fetchData = async(date) => {
        showLoadingScreen();

        if(!localStorage.getItem('groups') || localStorage.getItem('groups') && localStorage.getItem('groups').length === 0){
            setShowRoomSelector(true);
        }else{
            nightnurse_token_check(["1", "2", "5"]);

            const rooms = await getRooms();
            const streams = await getStreams(rooms);

            await Promise.all([streams, rooms]).then(async (result) => {
                var streamsArray = result[0];
                var roomsArray = result[1];
                const activitiesArray = [];

                Promise.all(streamsArray.map(async stream => {
                    const streamtasks = await getAlarmsFromRoom(stream.uuid, stream.name, date);
                    activitiesArray.push(...streamtasks);
                })).then(() => {
                    loadChart(date, roomsArray, activitiesArray); 
                });
            })  
        }

        hideLoadingScreen();
    }

    const goToDate = (date) => {
        navigate('/reports/activity/' + moment(date).format('YYYY-MM-DD'));
        window.location.reload();
    }

    useEffect(() => {
        const role = localStorage.getItem("Role");
        const rolesToCheck = ["1", "2", "5"];
        const token = localStorage.getItem('nightnursetoken');
        
        if ((rolesToCheck.includes(role)) && (!token || token === '')) {
            getToken();
        }
        
        setInterval(() => {
            if (rolesToCheck.includes(role)) {
                checkRefreshToken();
            }
        }, 5000)

        fetchData(props.router.params.reportdate);
    }, [])

    return (
        <React.Fragment>
            {loadingScreen && (<div className="loadingScreen"><div className="loader"></div></div>)}

            <LocationAlert />

            <div className='loc_content' style={{ position: 'relative' }}>
                <div className="filters">
                    {t('date')}: <DatePicker locale="nl" dateFormat="yyyy-MM-dd"
                        selected={currentdate}
                        onChange={(data, e) => goToDate(data)}
                    />

                    <button className='btn btn-blue' onClick={() => setShowRoomSelector(true)} style={{ float: 'none', marginLeft: '0px', marginTop: '0px' }}>Kies kamers</button>
                </div>
                
                {showRoomSelector && (<RoomSelector />)}

                <div className='legend'>
                    <div className='legend-item'>
                        <div className='legend-block ALARM_IN_BED_DETECTION'></div> {t('inbed')}
                    </div>
                    <div className='legend-item'>
                        <div className='legend-block ALARM_SOEB_DETECTION'></div> {t('soeb')}
                    </div>
                    <div className='legend-item'>
                        <div className='legend-block ALARM_OUT_OF_BED_DETECTION'></div> {t('outofbed')}
                    </div>
                    <div className='legend-item'>
                        <div className='legend-block ALARM_MAN_DOWN_DETECTION'></div> {t('fall')}
                    </div>
                    <div className='legend-item'>
                        <div className='legend-block ALARM_IN_BATHROOM_DETECTION'></div> {t('inbathroom')}
                    </div>
                    <div className='legend-item'>
                        <div className='legend-block ALARM_PERSON_VISIBLE_DETECTION'></div> {t('personvisible')}
                    </div>
                    <div className='legend-item'>
                        <div className='legend-block ALARM_OUT_OF_ROOM_DETECTION'></div> {t('outofroom')}
                    </div>
                    <div className='legend-item'>
                        <div className='legend-block ALARM_MISSING_BED_DETECTION'></div> {t('nobed')}
                    </div>
                    <div className='legend-item'>
                        <div className='legend-block ALARM_NO_DETECTION'></div> {t('nodetection')}
                    </div>
                </div>
                <div className="chart-view">
                    <div className="read-only"></div>
                    <div id="chart"></div>
                </div>
            </div>
        </React.Fragment>
    );

}

export default withRouter(ActivityReport);