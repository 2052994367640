import React, { useEffect, useState } from 'react';
import '../../../assets/style.css';
import withAuth from '../../../components/Login/withAuth';
import * as moment from 'moment';
import { fillDataTableFilters } from "../../../Functions.js";
import LocationAlert from "../../../components/LocationAlert.js";
import Filter from '../../../components/Filter';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Export from '../../../components/Export.js';
moment.locale('nl');

const HistoryAlarms = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [alarms, setAlarms] = useState([]);

    const getData = async () => {
        const functions = await import("../../../Functions.js");
      
        if (localStorage.getItem("currentLocation") && localStorage.getItem("currentLocation") !== '') {
            const data = await functions.fetchApi("mba", "/mba/alarm/history/get", "GET");

            setAlarms(data);
            functions.initDataTable();
        }
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>

                <h1>{t('history')}</h1>

                <div class="filters">
                    <Filter type="select" text={t('typealarm')} id="typeAlarm" column="0" item="reporthistoryalarm-alarmtype" exact={false} />
                    <Filter type="select" text={t('typeroom')} id="roomAlarm" column="1" item="reporthistoryalarm-room" exact={false} />
                    <Export />
                </div>

                <div className="DataTable_Container">
                <div className="table-responsive">
                    <table id="DataTable" className="showHead table responsive nowrap reports" width="100%">
                        <thead>
                            <tr className="rowtitle">
                                <th>{t('type')}</th>
                                <th>{t('object')}</th>
                                <th>{t('createdon')}</th>
                                <th>{t('solvedon')}</th>
                                <th>{t('status')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {alarms.map((alarm, i) => {
                                fillDataTableFilters(document.getElementById('typeAlarm'), alarm.alarmtype);
                                fillDataTableFilters(document.getElementById('roomAlarm'), alarm.object);

                                return (
                                    <tr key={i} onClick={() => { navigate(`/alarm/${alarm.objectid}`) }} className="historyAlarm">
                                        <td><div className="pictogram"> <img alt="alarm icon" src={`/images/icons/${alarm.icon}.png`} height="30" width="30" /></div>{t(`${alarm.slug}`)}</td>
                                        <td>{alarm.object}</td>
                                        <td>{moment(alarm.uptriggered).format('DD-MM-YYYY HH:mm:ss')}</td>
                                        <td>{moment(alarm.finishtriggered).format('DD-MM-YYYY HH:mm:ss')}</td>
                                        <td><div className='alarm-status' style={{ background: 'rgb(92, 184, 92)', display: 'inline-block', width: '10px', height: '10px', borderRadius: '100%', marginRight: '10px', boxShadow: 'rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px' }}></div> {t('solved')}</td>
                                    </tr>)
                                })}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withAuth(HistoryAlarms);
