import React, { useEffect, useState } from 'react';
import withRouter from '../components/withRouter';
import { useTranslation } from 'react-i18next';

const ObjectLocation = (props) => {
    const { t } =useTranslation();
    const [objectsData, setObjectsData] = useState([]);
    const [objecttype, setObjecttype] = useState('');
    const objectsList = ['', 'location', 'building', 'floor', 'location', 'room', 'floor'];

    const setObjectType = async (event) => {
        setObjecttype(event.target.value);
        await getData(event.target.value);
    }

    const getData = async (type) => {
        const functions = await import("../Functions");
        var data = '';

        if(props.type === 'organisation'){
            data = await functions.fetchApi("mba", `/mba/${objectsList[props.objectid - 1]}/get`, "GET", {
                "locationid": localStorage.getItem('currentLocation')
            });
        }else{
            const dataType = type && type > 0 ? objectsList[props.objectid - 1] : 'object';
            console.log(dataType);
            data = await functions.fetchApi("mba", `/mba/${dataType}/get`, "GET", {
                "locationid": localStorage.getItem('currentLocation')
            })
        }

        var objectsitems = [];

        data.forEach(item => {
            const mapping = {
                location: { OrgID: 1, ObjectID: item.locationid, ObjectName: item.locationname },
                building: { OrgID: 2, ObjectID: item.buildingid, ObjectName: item.buildingname },
                floor: { OrgID: 3, ObjectID: item.floorid, ObjectName: item.floorname },
                room: { OrgID: 4, ObjectID: item.roomid, ObjectName: item.roomname },
                employee: { OrgID: 5, ObjectID: item.employeeid, ObjectName: item.employeename },
                client: { OrgID: 6, ObjectID: item.clientid, ObjectName: item.clientname },
                sharedspace: { OrgID: 6, ObjectID: item.spaceid, ObjectName: item.spacename }
            };

            if(props.type === 'organisation'){
                objectsitems.push({
                    objectid: mapping[objectsList[props.objectid - 1]].ObjectID ,
                    objectname: mapping[objectsList[props.objectid - 1]].ObjectName
                }) 
            }else{
                if(type && type !== "0"){
                    objectsitems.push({
                        objectid: item.locationid || item.buildingid || item.floorid || item.roomid || item.employeeid || item.clientid || item.spaceid,
                        objectname: item.locationname || item.buildingname || item.floorname || item.roomname || item.employeename || item.clientname || item.spacename
                    }) 
                }else{
                    objectsitems.push({
                        objectid: item.objectid,
                        objectname: item.objectname
                    }) 
                }
                
            }
        });

        setObjectsData(objectsitems);
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            {props.type === 'device' && (
                <select onChange={(event) => setObjectType(event)} name="related" id="related" className="form-control">
                    <option value="0">{t('select')} {t('type')}</option>
                    <option value="location">{t('location')}</option>
                    <option value="building">{t('building')}</option>
                    <option value="floor">{t('floor')}</option>
                    <option value="room">{t('room')}</option>
                    <option value="employee">{t('employee')}</option>
                    <option value="client">{t('client')}</option>
                    <option value="sharedspace">{t('sharedspace')}</option>
                </select>
            )}
            
            
            <select name="location" id="location" className="form-control">
                <option value="0">{t('select')} {t('location')}</option>
                {objectsData.map((object, i) => {

                    var selected = false;

                    if (props.relatedid === object.objectid || props.objectid === object.objectid) {
                        selected = true;
                    }

                    return (<option value={object.objectid} selected={selected}>{object.objectname}</option>)
                })}
            </select>
        </React.Fragment>
    );
}

export default withRouter(ObjectLocation);