import React, { Component, useEffect, useState } from 'react';
import '../../assets/style.css';
import withRouter from '../../components/withRouter';
import * as moment from 'moment';
import { initDataTable } from "../../Functions";
import LocationAlert from '../../components/LocationAlert';
import { useTranslation } from 'react-i18next';
import { fetchApi } from "../../Functions.js";
moment.locale('nl');

const SettingsRoomDetails = (props) => {
    const { t } = useTranslation();
    const [room, setRoom] = useState([]);
    const [alarms, setAlarms] = useState([]);
    const [roomdevices, setRoomdevices] = useState([]);
    const [roomAlarms, setRoomalarms] = useState([]);
    const [devicetypes, setDevicetypes] = useState([]);
    const [devices, setDevices] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [locations, setLocations] = useState([]);
    const [logging, setLogging] = useState([]);

    const switchAlarm = async (event, alarmid, alarmname, roomname) => {
        const type = event.target.checked ? 'alarmenable' : 'alarmdisable';
        await fetchApi("mba", `/mba/${type}/${alarmid}`, "GET", {
            "alarmid": alarmid, "alarmname": alarmname, "objectid": props.router.params.id, "objectname": roomname
        });
    };

    const showError = (text) => {
        const errMsgElement = document.querySelector('.err_msg');
        errMsgElement.textContent = text;
        errMsgElement.style.display = 'block';
    }

    const hideError = () => {
        const errMsgElement = document.querySelector('.err_msg');
        errMsgElement.textContent = '';
        errMsgElement.style.display = 'none';
    }

    const switchDevice = async (event, device, objectID) => {
        const objectsData = await fetchApi("mba", "/mba/location/get", "GET", {
            "locationid": localStorage.getItem('currentLocation')
        });
        setLocations(objectsData);

      if (roomAlarms.length === 0) {
        hideError();

        const [data1, data2] = await Promise.all([
            await fetchApi("mba", `/mba/objectdevicechange/${device}`, "GET", {
                'deviceid': objectID, 'devicetype': 10, 'objectid': locations[0],
            }),
            await fetchApi("mba", `/mba/objectdevicechange/${device}`, "GET", {
                'deviceid': objectID, 'devicetype': '', 'objectid': event.target.value,
            })
        ])

        return [data1, data2];
      } else {
        showError("Er staan nog alarmen open voor deze kamer. Handel eerst deze alarmen af.");
      }
    };

    const getData = async () => {
        const [logging, alarms, room, alarmsData, roomDevices, devices, deviceTypes, routes] = await Promise.all([
            await fetchApi("mba", "/settings/responserules/get", "GET", {
                "locationid": localStorage.getItem('currentLocation'), "reportdate": '', "roomid": props.router.params.id
            }),
            await fetchApi("mba", "/alarm/get", "GET", {
                "locationid": localStorage.getItem('currentLocation')
            }),
            await fetchApi("mba", `/mba/room/get/${props.router.params.id}` , "GET", {
                "locationid": localStorage.getItem('currentLocation')
            }),
            await fetchApi("mba", `/mba/alarm/get/${props.router.params.id}` , "GET"),
            await fetchApi("mba", `/mba/room/device/get/${props.router.params.id}` , "GET"),
            await fetchApi("mba", "/mba/device/get" , "GET", {
                "locationid": localStorage.getItem('currentLocation')
            }),
            await fetchApi("mba", "/mba/devicetype/get" , "GET"),
            await fetchApi("mba", "/mba/route/get/" , "GET", {
                "locationid": localStorage.getItem('currentLocation')
            }),
        ])
        setLogging(logging);
        initDataTable();
        setRoomalarms(alarms.filter(alarm => alarm.objectid === props.router.params.id));
        setRoom(room[0]);
        setAlarms(alarmsData);
        setRoomdevices(roomDevices);
        setDevices(devices);
        setDevicetypes(deviceTypes);
        setRoutes(routes);
    };

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className="loc_content">
                <div class="col-md-12">
                    <h1><button className="goBackBtn" onClick={() => window.history.back()}><i class="fas fa-arrow-circle-left"></i></button> {t('alarms')} {room.roomname}</h1>
                    <div class="orgDetails">
                    <div className="table-responsive">
                        <table>
                            <tbody>
                                {alarms.map((roomalarm, i) => {
                                    var checked = roomalarm.enabled === 1 ? true : false;

                                    return (
                                        <tr key={i}>
                                            <td>{roomalarm.incomingmessagename}</td>
                                            <td>{roomalarm.outgoingmessagename}</td>
                                            <td>{roomalarm.outgoingroutename}</td>
                                            <td>
                                                <label class='switch'>
                                                    <input id={`Roomalarm_${roomalarm.alarmid}`} defaultChecked={checked} onChange={(event) => switchAlarm(event, roomalarm.alarmid, roomalarm.incomingmessagename, room.roomname)} class='AlarmtogBtn' type='checkbox' />
                                                    <span class='slider round'></span></label>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
                
                <div className='col-md-12'>
                    <h1>{t('logging')} {room.roomname}</h1>
                    <div className="DataTable_Container" style={{ marginBottom: '25px' }}>
                    <div className="table-responsive">
                        <table id="DataTable" class="showHead table responsive nowrap" width="100%">
                            <thead>
                                <tr>
                                    <th>{t('alarm')}</th>
                                    <th>{t('date')}/{t('time')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {logging.map((log, i) => {
                                    const dotColor = log.active === 2 ? '#FF0000' : '#00FF00';
                                    const msg = log.active === 2 ? 'uitgezet' : 'aangezet';
                                    const ruleDate = log.ruledate && log.ruledate !== '0000-00-00 00:00:00' ? moment(log.ruledate).format('DD-MM-YYYY') : 'onbekend';
                                    const ruleTime = ruleDate !== 'onbekend' ? moment(log.ruledate).format('HH:mm:ss') : 'onbekend';

                                    return (
                                        <tr>
                                            <td>{log.message}</td>
                                            <td>{msg} op {ruleDate} om {ruleTime}</td>
                                        </tr>)
                                })}
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <h1>{t('devices')} {room.roomname}</h1>
                    <div className='err_msg'></div>
                    <div class="orgDetails">
                    <div className="table-responsive">
                        <table>
                            <tbody>
                                {roomdevices.map((roomdevice, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{roomdevice.devicetypename}</td>
                                            <td id={`devicetype_${roomdevice.devicetypeid}`}>
                                                <select onChange={(event) => switchDevice(event, roomdevice.deviceid, room.roomid)} value={roomdevice.deviceid} class="form-control device" data-object={room.roomid} id={`device_${roomdevice.deviceid}`}>
                                                    <option value="0">{t('select')}</option>
                                                    {devices.map((device, i) => {
                                                        return device.devicetypeid === roomdevice.devicetypeid ? (
                                                            <option key={i} value={device.deviceid}>{device.devicename}</option>
                                                        ) : null;
                                                    })}
                                                </select>
                                            </td>
                                        </tr>)
                                })}
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(SettingsRoomDetails);