import React, { useEffect, useState } from 'react';
import withAuth from '../../components/Login/withAuth';
import Menu from '../../components/MbaMenu';
import { checkMBAUserPermission } from './Functions';
import { fetchApi } from "../../Functions";
import { useNavigate } from 'react-router';

const Status = () => {

    const navigate = useNavigate();

    const [statussen, setStatussen] = useState([]);

    const updateStatus = async (id, name, command) => {
        const data = await fetchApi("mba", `/mba/admin/status/${id}/${command}`, "GET" , {
            "processname": name
        })
        window.location.reload();
    }

    const getData = async() => {
        const getStatussen = await fetchApi("mba", "/mba/admin/status/get", "GET");
        setStatussen(getStatussen);
    }

    useEffect(() => {
        checkMBAUserPermission();
        getData();
    }, [])

    return(
        <React.Fragment>
            <Menu />
            <h2>Status</h2>

            <div className="DataTable_Container">
                <table id="DataTable" className="showHead table responsive nowrap" width="100%">
                    <thead>
                        <tr className="rowtitle">
                            <th>Connector</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Enabled</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {statussen.map((status, i) => {
                            var dotColorStatus = status.status === 0 ? 'red' : 'green';
                            var dotColorEnabled = status.active === 0 ? 'red' : 'green';

                            return (
                            <tr key={i}>
                                <td onClick={() => status.name != 'MBA' && status.name != 'Watchdog' ? navigate(`/mba-admin/status/${status.connectionid}`) : ''} className={status.active == 0 ? 'italic disabledText' : ''}>{status.name}</td>
                                <td className={status.active == 0 ? 'italic disabledText' : ''}>{status.description}</td>
                                <td className={status.active == 0 ? 'italic disabledText' : ''}><div style={{ width: '10px', height: '10px', background: dotColorStatus, borderRadius: '100%' }}></div></td>
                                <td className={status.active == 0 ? 'italic disabledText' : ''}><div style={{ width: '10px', height: '10px', background: dotColorEnabled, borderRadius: '100%' }}></div></td>
                                <td className={status.active == 0 ? 'actions italic disabledText' : 'actions'}>
                                    {status.active == 1 && status.status == 0 ? <i onClick={() => updateStatus(status.connectionid, status.connector, 'start')} class="fa fa-play" style={{ color: '#0085CA' }}></i> : <i class="fa fa-play" style={{ color: '#ccc' }}></i>}
                                    {status.active == 1 && status.status == 1 ? <i onClick={() => updateStatus(status.connectionid, status.connector, 'stop')} class="fa fa-stop" style={{ color: '#0085CA' }}></i> : <i class="fa fa-stop" style={{ color: '#ccc' }}></i>}
                                    {status.active == 1 ? <i class="fa fa-sign-out" onClick={() => updateStatus(status.connectionid, status.connector, 'restart')} style={{ color: '#0085CA' }}></i> : <i class="fa fa-sign-out" style={{ color: '#ccc' }}></i>}
                                </td>
                            </tr>);
                        })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}

export default withAuth(Status);