import React, { useState, useEffect } from 'react';
import '../../../assets/style.css';
import { crudAlertControl } from "../../../Functions.js";
import LocationAlert from '../../../components/LocationAlert';
import { useTranslation } from 'react-i18next';
import withRouter from '../../../components/withRouter.js';
import { useNavigate } from 'react-router';

const PhonebookDetails = (props) => {
    const [contact, setContact] = useState([]);
    const [teams, setTeams] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const getData = async () => {
        const functions = await import("../../../Functions.js");

        const currentContact = await functions.fetchApi("mba", `/mba/phonebook/external/contact/${props.router.params.id}`, "GET", {
            "contactid": props.router.params.id
        });

        const teamData = await functions.fetchApi("mba", "/mba/team/get/", "GET", {
            "showHidden": true
        });
        setContact(currentContact[0]);
        setTeams(teamData);
    }

    const changeData = async (event) => {
        event.preventDefault();
        const functions = await import("../../../Functions.js");
        const data = await functions.fetchApi("mba", `/mba/phonebook/external/update/${props.router.params.id}`, "GET", {
            "name": event.target.name.value,
            "phonenumber": event.target.phone.value,
            "teamid": event.target.team.value,
            "contactid": props.router.params.id
        });

        crudAlertControl('update', data);
        getData();
    }

    useEffect(()=> {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <form type="POST" onSubmit={(event) => changeData(event)}>
                    <input className="btn btn-add" type="submit" name="submit" value="Opslaan" />
                    <h1><button className="goBackBtn" onClick={() => navigate('/phonebook/external')}><i class="fas fa-arrow-circle-left"></i></button> {contact.name}</h1>

                    <div className="alert alert-success alert-update" role="alert">
                        {t('updatecontact')}
                    </div>

                    <div class="teamDetails">
                        <table class="table">
                            <tr class="roweven">
                                <td class="td">{t('name')}</td>
                                <td class="td"><input key={`${Math.floor((Math.random() * 1000))}-min`} type="text" name="name" id="name" defaultValue={contact.name} className="form-control" /></td>
                            </tr>
                            <tr class="rowodd">
                                <td class="td">{t('phonenumber')}</td>
                                <td class="td"><input key={`${Math.floor((Math.random() * 1000))}-min`} type="text" name="phone" id="phone" defaultValue={contact.phonenumber} className="form-control" /></td>
                            </tr>
                            <tr class="rowodd">
                                <td class="td">{t('team')}</td>
                                <td class="td">
                                    <select key={`${Math.floor((Math.random() * 1000))}-min`} name="team" id="team" className="form-control" defaultValue={contact.team} required>
                                        {teams.map((team, i) => {
                                            return (<option value={team.teamid}>{team.name}</option>)
                                        })}
                                    </select>
                                </td>
                            </tr>
                        </table>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}

export default withRouter(PhonebookDetails);