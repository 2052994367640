import React, { Component, useEffect, useState } from 'react';
import withAuth from '../../components/Login/withAuth';
import Menu from '../../components/MbaMenu';
import { checkMBAUserPermission, confirmDelete, crudAlertControl, initDataTable } from './Functions';
import { useNavigate } from 'react-router';
import ModalDelete from '../../components/MbaModalDelete';
import { fetchApi } from "../../Functions";

const Clusters = () => {
    const navigate = useNavigate();
    const [clusters, setClusters] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const deleteCluster = async (clusterid) => {
        const data = await fetchApi("mba", `/mba/admin/responserule/clusters/${clusterid}/delete`, "GET" , {
            "clusterid": clusterid
        })
        crudAlertControl('delete', data);
        await getData();
    }

    const addCluster = async (event) => {
        event.preventDefault();
        const data = await fetchApi("mba", "/mba/admin/responserule/clusters/insert", "GET" , {
            "clustername": event.target.clustername.value, "parentid": event.target.parentid.value
        })
        setShowModal(false);
        crudAlertControl('add', data);
        await getData();
    }

    const getData = async () => {
        const data = await fetchApi("mba", "/mba/admin/responserule/clusters", "GET");
        setClusters(data);
        initDataTable();
    }

    useEffect(() => {
        checkMBAUserPermission();
        getData();
    }, [])

    return(
        <React.Fragment>
            <Menu />

            <div className="alert alert-success alert-add" role="alert">
                Cluster aangemaakt.
            </div>

            <div className="alert alert-success alert-delete" role="alert">
                Cluster verwijderd.
            </div>

            <div className="alert alert-danger" role="alert">
                Er is iets niet goed gegaan. Probeer het later nog eens.
            </div>

            <h2>Responserules Clusters</h2>
            <div onClick={() => { navigate("/mba-admin/responserules") }} className="btn btn-blue">Responserules</div>
            <div onClick={() => setShowModal(true)} className="btn btn-add">Toevoegen</div>

            <div className="DataTable_Container">
                <table id="DataTable" className="showHead table responsive nowrap" width="100%">
                    <thead>
                        <tr className="rowtitle">
                            <th>Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {clusters.map((cluster, i) => {

                            return (
                            <tr key={i}>
                                <td>{cluster.name}</td>
                                <td>
                                    <i onClick={() => confirmDelete(function(){ deleteCluster(cluster.id) })} style={{ color: '#ff0000', marginTop: '3px', cursor: 'pointer' }} class="fas fa-trash-alt"></i>
                                </td>
                            </tr>);
                        })}
                    </tbody>
                </table>
            </div>

            <ModalDelete />

            {showModal && ( 
            <div style={{ display: 'block' }} id="add" className="modal fade in">
                <div className="modal-content ">
                    <div className="modal-header">
                        <h4 className="modal-title">Cluster toevoegen</h4>
                        <div onClick={() => setShowModal(false)} className="close">&times;</div>
                    </div>

                    <div className="modal-body">
                        <form onSubmit={(event) => addCluster(event)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="clustername">Naam</label>
                                        <input type="text" name="clustername" id="clustername" className="form-control" required />
                                    </div>
                                    <div className="form-group">
                                        <label for="parentid">Parent</label>
                                        <select id="parentid" key={`${Math.floor((Math.random() * 1000))}-min`} name="parentid" className='form-control'>
                                            <option value="0">Selecteer cluster</option>
                                            {clusters.map((cluster, i) => {
                                                return <option key={i} value={cluster.id}>{cluster.name}</option>;
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div onClick={() => setShowModal(false)} id="cancel" className="btn btn-red cancel">Annuleren</div>
                                </div>
                                <div className="col-md-6">
                                    <button type="submit" className="btn btn-green">Toevoegen</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div> )}

        </React.Fragment>
    )
}

export default withAuth(Clusters);