import React, { useState, useEffect } from 'react';
import '../../../assets/style.css';
import withRouter from '../../../components/withRouter';
import { initDataTable, filterDataTable, fillDataTableFilters, fetchApi } from "../../../Functions.js";
import LocationAlert from '../../../components/LocationAlert';
import { useTranslation } from 'react-i18next';
import Filter from '../../../components/Filter';
import Export from '../../../components/Export.js';

const PhonebookInternal = () => {
    const [contacts, setContacts] = useState([]);
    const { t } = useTranslation();

    contacts.map((contact, i) => {
        contact.teams.map((team, i) => {
            fillDataTableFilters(document.getElementById('EmployeesTeams'), team.name);
        });
    })

    const getData = async() => {
        const data = await fetchApi("mba", "/mba/phonebook/get/", "GET");

        await Promise.all(
            data.map(async (user) => {
                const [device, teams] = await Promise.all([
                    await fetchApi("mba", `/mba/phonebook/device/${user.deviceid}`, "GET", {
                        "deviceid": user.deviceid
                    }),
                    await fetchApi("mba", `/phonebook/get/${user.id}/teams`, "GET", {
                        "contactid": user.id,
                    })
                ]);

                user.devicedata = device;
                user.teams = teams;
            })
        );

        setContacts(data);
        initDataTable();
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <React.Fragment>
            <LocationAlert />
            
            <div className="loc_content">
                <h1>{t('phonebook')} - {t('phonebookintern')}</h1>
                <div className="filters">
                    <Filter type="select" text={t('team')} id="EmployeesTeams" column="1" item="reporthistoryalarm-employeesteams" exact={false} />
                    <Export />
                </div>

                <div className="DataTable_Container">
                <div className="table-responsive">
                    <table id="DataTable" className="showHead table responsive nowrap">
                        <thead>
                            <tr>
                                <th>{t('user')}</th>
                                <th>{t('teams')}</th>
                                <th>{t('device')}</th>
                                <th>{t('devicenumber')}</th>
                            </tr>
                        </thead>
                    <tbody>
                        {contacts.map((contact, i) => {
                          return (
                            <tr key={i}>
                                <td><div className="contact-icon">{contact.user.charAt(0)}</div> {contact.user}</td>
                                <td>
                                    {contact.teams.map((team, i) => {
                                      return <div className='contact-team'>{team.name}</div>;
                                    })}
                                </td>
                                <td>{contact.device}</td>
                                <td>
                                    {contact.devicedata.map((device, i) => {
                                      return device.devicetypeparameterid === 3 ? device.devicetypeparameterid === 3 : null
                                    })}
                                </td>
                            </tr>
                            )
                        })}
                    </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );  
}

export default withRouter(PhonebookInternal);