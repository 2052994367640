import React, { Component } from 'react';
import "../assets/style.css";
import withAuth from '../components/Login/withAuth';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();
  
  return (
    <React.Fragment>
      <div class="notfound">
        <img alt="not-found" src="/images/404-icon.jpg" />
        <div className="notfound-text">
          <h2>{t('404')}</h2>
          <p>{t('404text')}</p>
          <button className="btn-goback" onClick={() => { window.history.back(); }}>{t('helpmeback')}</button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withAuth(NotFound);
