import React, { useEffect, useState } from 'react';
import '../../../assets/style.css';
import * as moment from 'moment';
import { initDataTable, fillDataTableFilters } from "../../../Functions.js";
import withRouter from '../../../components/withRouter';
import { registerLocale } from "react-datepicker";
import LocationAlert from "../../../components/LocationAlert.js";
import "react-datepicker/dist/react-datepicker.css";
import nl from 'date-fns/locale/nl';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Filter from '../../../components/Filter';
registerLocale('nl', nl);
moment.locale('nl');

const AlarmLogging = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [logging, setLogging] = useState([]);
    const [users, setUsers] = useState([]);
    const [objects, setObjects] = useState([]);
    const [modules, setModules] = useState([]);
    var currentdate = new Date(props.router.params.reportdate);

    const getAlarmLogging = async (date) => {
        const functions = await import("../../../Functions.js");
        const data = await functions.fetchApi("mba", "/mba/report/alarmlogging", "GET", {
            "reportdate": date
        });

        document.getElementById('objectAlarm').innerHTML = "<option value='0'>Selecteer object</option>";
        document.getElementById('userAlarm').innerHTML = "<option value='0'>Selecteer gebruiker</option>";

        data.forEach(item => {
            fillDataTableFilters(document.getElementById('objectAlarm'), item.object);
            fillDataTableFilters(document.getElementById('userAlarm'), item.user);
        })

        setLogging(data);
        initDataTable();
    }

    const goToDate = (date) => {
        navigate('/reports/alarmlogging/' + moment(date).format('YYYY-MM-DD'));
        getAlarmLogging(moment(date).format('YYYY-MM-DD'));
    }

    useEffect(() => {
        getAlarmLogging(props.router.params.reportdate);
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <h1>{t('alarmlogging')}</h1>
                <div class="filters">
                    <Filter type="date" text={t('date')} callback={(data, e) => goToDate(data)} />
                    <Filter type="select" text={t('object')} id="objectAlarm" column="2" item="reporthistoryalarm-alarmobject" exact={false} />
                    <Filter type="select" text={t('alarm')} id="alarm" column="1" item="reporthistoryalarm-alarmid" exact={false} />
                    <Filter type="select" text={t('user')} id="userAlarm" column="3" item="reporthistoryalarm-alarmuser" exact={false} />

                    <div id="buttons" style={{ float: 'right' }}></div>
                </div>

                {module.description && module.description !== '' ?
                    <div className="help">
                        {module.description}
                    </div> : ''}

                    <div className="DataTable_Container">
                    <div className="table-responsive">
                        <table id="DataTable" class="showHead table responsive nowrap" width="100%">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{t('alarm')}</th>
                                    <th>{t('object')}</th>
                                    <th>{t('user')}</th>
                                    <th>{t('date')}/{t('time')}</th>
                                    <th>{t('active')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {logging.map((log, i) => {

                                    fillDataTableFilters(document.getElementById('objectAlarm'), log.object);
                                    fillDataTableFilters(document.getElementById('userAlarm'), log.user);
                                    fillDataTableFilters(document.getElementById('alarm'), log.alarm);

                                    var dotColor = log.action === 0 ? '#FF0000' : '#00FF00';

                                    return (
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{log.alarm}</td>
                                            <td>{log.object}</td>
                                            <td>{log.user}</td>
                                            <td>{moment(log.dateaction).format('DD-MM-YYYY HH:mm:ss')}</td>
                                            <td><div style={{ width: '10px', height: '10px', background: dotColor, borderRadius: '100%' }}></div></td>
                                        </tr>)
                                })}
                            </tbody>
                        </table>
                        </div>
                    </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(AlarmLogging);
