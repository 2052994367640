import React, { Component, useEffect } from 'react';
import withRouter from '../../components/withRouter';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
moment.locale('nl');

const AlarmAlert = () => {
    const { t } = useTranslation();

    const getData = async () => {
        const functions = await import("../../Functions.js");
        const [alert, data] = await Promise.all([
            functions.fetchApi("mba", "/mba/settings/openalarms/alert", "GET"),
            functions.fetchApi("mba", "/alarm/get", "GET", {
                "locationid": localStorage.getItem('currentLocation')
            })
        ])

        data.forEach(async (alarm) => {
            var now = moment();
            var end = alarm.down ? moment(alarm.down) : null;

            if (end != null) {
                var duration = moment.duration(now.diff(end));
                var minutes = duration.asMinutes();

                if (minutes > alert[0].value) {
                    document.querySelector(".openalarms_alert").style.display = 'block';
                }
            }
        })
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <div className="openalarms_alert"><i className="fas fa-bell"></i> {t('pending_alarms')}</div>
        </React.Fragment>
    );
}

export default withRouter(AlarmAlert);
