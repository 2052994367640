import React, { Component, useEffect } from 'react';
import '../../assets/style.css';
import withAuth from '../../components/Login/withAuth';
import { initDataTable, confirmDelete, fetchApi, postAxios } from "../../Functions.js";
const $ = require('jquery');

const SettingsVideo = () => {
  const [videos, setVideos] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState({});

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  }

  const onFileUpload = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("file", selectedFile);

    const upload = await postAxios("/mba/settings/video/upload", data, {
      "Authorization": "Bearer " + localStorage.getItem('id_token'), "Content-Type": "application/x-www-form-urlencoded", "userid": localStorage.getItem('UserID'), "customerid": localStorage.getItem('CustomerID'), 'filename': selectedFile.name
    });
    await getData();
  }

  const uploadvideo = async(e) => {
    const data = await fetchApi("mba", "/mba/settings/video/insert", "GET", {
      "name": e.target.files[0].name, "type": "helpvideo"
    })
    await getData();
  }

  const deletevideo = async (row, videofile) => {
    const data = await fetchApi("mba", "/mba/settings/video/delete", "GET", {
      'filename': videofile
    })
    await getData();
  }

  const getData = async() => {
    const data = await fetchApi("mba", "/mba/settings/video/get", "GET");
    console.log(data);
    setVideos(data);
    initDataTable();
  }

  useEffect(() => {
    getData();
  }, [])

  return (
    <React.Fragment>
      <h2>Instructievideo</h2>
      <div class="row">
        <div class="col-md-12">

          <form action="" method="POST" encType="multipart/form-data" onSubmit={onFileUpload}>
            <div class="uploadfield">
              <input onChange={onFileChange} type="file" name="target_file" required />
              <input type="submit" value="Uploaden" />
            </div>
          </form>

          <h2>Alle video's</h2>
          <div className="DataTable_Container">
            <table id="DataTable" class="table responsive nowrap sortTable" width="100%">
              <thead>
                <tr class="rowtitle">
                  <th>Naam</th>
                  <th>Verwijderen</th>
                </tr>
              </thead>
              <tbody>
                {videos ? videos.map((video, i) => {
                  return (
                    <tr data-videoid={video.id}>
                      <td onClick={() => { window.open(window.location.origin + '/video/' + video.file, '_blank'); }}><i class="fas fa-video"></i> {video.file}</td>
                      <td style={{ textAlign: 'right' }}><i style={{ marginRight: '10px' }} onClick={(event) => confirmDelete(function () { deletevideo(event, video.file) })} class="btn-delete fas fa-trash-alt"></i></td>
                    </tr>)
                }) : ''}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="modal" id='modal_dialog'>
        <div className="modal-content" style={{ textAlign: 'center' }}>
          <div className="modal-header">
            <h4 className="modal-title">Verwijderen</h4>
            <div className="close">&times;</div>
          </div>
          <div className="modal-body">
            <i class="fas fa-exclamation-circle"></i>
            <div class='modalTitle'></div>

            <div className="row">
              <div className="col-md-6" style={{ padding: '0' }}>
                <button id="cancel btnNo" className="btn btn-red cancel">Annuleren</button>
              </div>
              <div className="col-md-6" style={{ padding: '0' }}>
                <button id='btnYes' className="btn btn-green">Verwijderen</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withAuth(SettingsVideo);
