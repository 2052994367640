import React, { useEffect, useState } from 'react';
import * as moment from 'moment';
import Menu from '../../components/MbaMenu.js';
import DatePicker from "react-datepicker";
import { checkMBAUserPermission, initDataTable } from './Functions';
import { filterDataTable, fetchApi } from '../../Functions';
import withRouter from '../../components/withRouter';
import { useNavigate } from 'react-router';
moment.locale('nl');

const MBALog = (props) => {
    const navigate = useNavigate();
    const [logs, setLogs] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    var currentdate = new Date(props.router.params.date);

    const getData = async (datefrom, dateto) => {
        dateto = dateto && dateto !== '' ? dateto : datefrom;
        const data = await fetchApi("mba", "/mba/admin/log/get", "GET", {
            "datefrom": datefrom, "dateto": dateto
        });
        setLogs(data);
        initDataTable();
    }

    const changeDate = (date, date2, init) => {
        var url = window.location.href.split("/");
        if (!date2 || date2 && date2 === '') {
            date2 = date;
        }

        navigate(date2 && date2 !== '' ? '/' + url[3] + '/' + url[4] + '/' + date + '/' + date2 : '/' + url[3] + '/' + url[4] + '/' + date);
        getData(date, date2);
    }

    useEffect(() => {
        checkMBAUserPermission();
        getData(props.router.params.date);
    }, [])

    return(
        <React.Fragment>
            <Menu />

            <div class="filters" style={{ marginTop: '2px', textAlign: 'center' }}>
                Periode:
                <DatePicker locale="nl" dateFormat="yyyy-MM-dd"
                    selected={startDate ? new Date(startDate) : currentdate}
                    onChange={(data) => setStartDate(moment(data).format('YYYY-MM-DD'))}
                />

                <DatePicker locale="nl" dateFormat="yyyy-MM-dd"
                    selected={endDate ? new Date(endDate) : ''}
                    onChange={(data) => setEndDate(moment(data).format('YYYY-MM-DD'))}
                />

                <div class="search-btn" onClick={() => changeDate(startDate, endDate)} style={{ marginRight: '20px' }}><i class="fa fa-search" style={{ color: '#ffffff' }}></i></div>

                Logtype:
                <select id="type" onChange={(event) => filterDataTable("logtype", 0, event.target.value, false)}>
                    <option value="">Selecteer type</option>
                    <option value="mba">MBA</option>
                    <option value="watchdog">Watchdog</option>
                </select>
            </div>
            
            <h2>Log</h2>

            <div className="DataTable_Container">
                <table id="DataTable" class="showHead table responsive nowrap" width="100%">
                    <thead>
                    <tr class="rowtitle">
                        <th>Logtype</th>
                        <th>Method</th>
                        <th>Info</th>
                        <th>Date</th>
                        <th>From</th>
                    </tr>
                    </thead>
                    <tbody>
                    {logs.map((log, i) => {
                        return (
                        <tr key={i}>
                            <td>{log.logtype}</td>
                            <td>{log.method}</td>
                            <td>{log.info}</td>
                            <td>{moment(log.date).format('DD-MM-YYYY HH:mm')}</td>
                            <td>{log.from}</td>
                        </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}

export default withRouter(MBALog);