import React, { Component, createRef } from 'react';
import withAuth from '../../components/Login/withAuth';
import Hls from 'hls.js';

const Hikvision = () => {

  var video = document.getElementById('video');
  var videoSrc = 'http://localhost:8000/streams/output.m3u8';
  if (Hls.isSupported()) {
    var hls = new Hls();
    hls.loadSource(videoSrc);
    hls.attachMedia(video);
  }

  return (
    <React.Fragment>
      <h2>HikVision Test</h2>

      <div>
        <video
          autoPlay
          controls
          style={{ height: 300 }}
        ></video>
      </div>
    </React.Fragment>
  );
}

export default withAuth(Hikvision);
