import React, { Component, useState, useEffect } from 'react';
import '../../assets/style.css';
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from 'date-fns/locale/nl';
import * as moment from 'moment';
import withRouter from '../../components/withRouter';
import { useTranslation } from 'react-i18next';
moment.locale('nl');
registerLocale('nl', nl);

const TrackingDetail = (props) => {
    const { t } = useTranslation();
    const [device, setDevice] = useState([]);
    const [history, setHistory] = useState([]);

    const getData = async () => {
        const functions = await import("../../Functions.js");
        const [getDevice, deviceInfo] = await Promise.all([
            functions.fetchApi("mba", "/mba/blyott/devices/info", "GET", {
                "mac": props.router.params.mac, "limit": 1
            }), 
            functions.fetchApi("mba", "/mba/blyott/devices/info", "GET", {
                "mac": props.router.params.mac, "limit": 100
            }), 
        ])

        setDevice(getDevice[0]);
        setHistory(deviceInfo);
        functions.initDataTable();
    }

    useEffect(() => {
        getData();
    }, [])

    const div = document.querySelector('div');

    if (div.classList.contains('batOrange')) {
        document.querySelectorAll('.batOrange').forEach(element => element.classList.add('active'));
    }

    const currentdate = new Date();
    const currentTemp = Math.floor(device.temp);

    const batStatus = device.batlevel < 10 ? 'batRed' : (device.batlevel < 20 ? 'batOrange' : 'batGreen');

    return (
        <React.Fragment>
            <div className='loc_content'>
                <h1><button className="goBackBtn" onClick={() => window.location.href = "/tracking"}><i class="fas fa-arrow-circle-left"></i></button> {device.devicename}</h1>

                <div className="container-fluid">
                    <div className="row">
                        <div className='col-md-12'>
                            <div className={`batMsg ${batStatus}`} style={{ margin: '0px 30px 15px 30px' }}>
                                <div className={`border-top ${batStatus}`} style={{ height: '3px' }}></div>
                                <span style={{ padding: '15px', display: 'block', color: '#333', fontWeight: '600' }}>Batterij bijna leeg</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid" style={{ padding: '0' }}>
                    <div className="row" style={{ margin: '0 15px' }}>
                        <div className='col-md-4 col-sm-12'>
                            <div className='card'>
                                <div className="card-body">
                                    <table>
                                        <tr>
                                            <td>{t('mac')}</td>
                                            <td>{device.tagmac ? device.tagmac.match(/.{1,2}/g).join(':') : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('currentlocation')}</td>
                                            <td>{device.location}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('lastsignal')}</td>
                                            <td>{moment(device.time).format("DD-MM-YYYY HH:mm:ss")}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4 col-sm-12'>
                            <div className='card batteryStatus'>
                                <h3><i class="fa-solid fa-battery-full"></i> <span>{t('battery')}</span></h3>
                                <div className="card-body">
                                    <div class={`progress ${batStatus}`}><div class="progress-bar" role="progressbar" aria-valuenow={device.batlevel} aria-valuemin="0" aria-valuemax="100" style={{ width: device.batlevel + "%" }}><div class="progress-bar-number">{device.batlevel} %</div></div></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-12'>
                            <div className='card batteryStatus'>
                                <h3><i class="fa-solid fa-temperature-three-quarters"></i> <span>{t('temperature')}</span></h3>
                                <div className="card-body">
                                    <span className='currentTemp'>{currentTemp} &#8451;</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <h2 className="location-history-header">{t('locationhistory')}</h2>
                            <div className="DataTable_Container">
                            <div className="table-responsive">
                                <table id="DataTable" class="showHead table responsive nowrap" width="100%">
                                    <thead>
                                        <tr className="rowtitle">
                                            <th>{t('date')}</th>
                                            <th>{t('name')}</th>
                                            <th>{t('location')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {history.map((log, i) => {
                                            return (
                                                <tr>
                                                    <td>{moment(log.time).format('DD-MM-YYYY HH:mm:ss')}</td>
                                                    <td>{log.devicename}</td>
                                                    <td>{log.location ? log.location : 'Unknown'}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(TrackingDetail);