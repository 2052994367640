import React from 'react';
import * as moment from 'moment';
import LocationAlert from '../../../components/LocationAlert';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import withRouter from '../../../components/withRouter';
import { initDoughnut, initHorizontal } from '../../../components/Chart';
import { fetchApi } from "../../../Functions.js";
import { SvelteGantt, SvelteGanttTable, MomentSvelteGanttDateAdapter, SvelteGanttDependencies } from 'svelte-gantt';
import Filter from '../../../components/Filter';
import VirtualRoundCard from '../../../components/VirtualRoundCard.js';
moment.locale('nl');
const $ = require('jquery');

const RoomInsightsList = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [room, setRoom] = useState([]);
    const [roomtimes, setRoomtimes] = useState([]);
    const [roomtop, setRoomtop] = useState([]);
    const [roomstreams, setRoomstreams] = useState([]);
    const [dayweek, setDayweek] = useState('day')
    const [currentstream, setCurrentstream] = useState([]);
    const [currentday, setCurrentday] = useState(moment(props.router.params.reportdate).format("YYYY-MM-DD"));
    const [loadingScreen, setLoadingScreen] = useState(true);
    const [roleSpecs, setRoleSpecs] = useState(false);

    const changeDateData = async (reportdate, currentstream) => {
        setCurrentday(moment(reportdate).format('YYYY-MM-DD'));
        navigate('/reports/roominsights/' + props.router.params.id + '/' + moment(reportdate).format('YYYY-MM-DD'));
        await setCurrentStream(currentstream.uuid, moment(reportdate).format('YYYY-MM-DD'));
    }

    const setCurrentStream = async (streamid, reportdate) => {
        const stream = roomstreams.find((stream) => stream.uuid === streamid);
        await getData(stream, reportdate);
    }

    const getStreamTimes = async (streamid, date) => {
        return await fetchApi("kepler", "/nightnurse/reports/stream/times", "GET", {
            "streamid": streamid,
            "reportdate": date
        })
    }

    const getTopAlarms = async (streamid, date) => {
        return await fetchApi("kepler", "/nightnurse/reports/stream/topalarms", "GET", {
            "streamid": streamid,
            "reportdate": date
        })
    }

    const changedayweek = async (value) => {
        getData(currentstream, moment(props.router.params.reportdate).format('YYYY-MM-DD'), value);
    }

    const loadChart = (date, getRooms, getActivities) => {
        var currentStart = moment(date + " 00:00:00");
        var currentEnd = moment(date + " 23:59:59");

        const data = {
            rows: getRooms,
            tasks: getActivities,
            dependencies: []
        };

        let options = {
            dateAdapter: new MomentSvelteGanttDateAdapter(moment),
            rows: data.rows,
            tasks: data.tasks,
            dependencies: data.dependencies,
            timeRanges: [],
            columnUnit: "hour",
            columnOffset: 1,
            magnetUnit: "hour",
            magnetOffset: 1,
            rowHeight: 52,
            rowPadding: 6,
            headers: [
                { unit: "hour", format: "H", sticky: true }
            ],
            fitWidth: true,
            from: currentStart,
            to: currentEnd,
            tableHeaders: [
                { title: '', property: "label", width: 140, type: "tree" }
            ],
            tableWidth: 240,
            ganttTableModules: [SvelteGanttTable],
            ganttBodyModules: [SvelteGanttDependencies]
        };

        new SvelteGantt({
            target: document.getElementById('chart'),
            props: options
        });
    }

    function getCurrentWeek(reportdate) {
        var weekStart = moment(reportdate).startOf('week');
        var weekEnd = moment(reportdate).endOf('week');
      
        var days = [];
      
        for (var i = 0; i <= 6; i++) {
            days.push({
                id: moment(weekStart).add(i, 'days').format("DD"),
                label: moment(weekStart).add(i, 'days').format("DD-MM")
            });
        }
        return days;
      }

    const getData = async (stream, reportdate, dayweekvalue) => {
        setLoadingScreen(true);
        const functions = await import("../../../Functions.js");
        functions.nightnurse_token_check(["1", "2", "5"]);

        const role = await fetchApi("mba", "/mba/modules/roles/get/" + localStorage.getItem('Role'), "GET", {
            "roleid": localStorage.getItem('Role')
        }) 
        setRoleSpecs(role);

        const roomInfo = await fetchApi("mba", "/mba/room/get/" + props.router.params.id, "GET");
        const devices = await fetchApi("mba", "/nightnurse/devices/", "GET", {
            "roomid": props.router.params.id
        });

        var streamsofroom = await Promise.all(devices.map(async device => 
            await fetchApi("kepler", "/nightnurse/stream/id", "GET", {
                "streamid": device.kepler,
            })
        ));

        setRoomstreams(streamsofroom);
        setRoom(roomInfo[0]);

        var streamtimes = [];
        var activities = [];
        var weekactivities = [];
        var roomtopalarms = [];
        var streamtimesresult = [];

        if(!currentstream || currentstream && currentstream.length == 0 || !stream){
            var streams = [
                {
                    id: streamsofroom[0].name,
                    label: streamsofroom[0].name
                }
            ]

            var weekdates = getCurrentWeek(reportdate);

            setCurrentstream(streamsofroom[0]);
            streamtimes = await getStreamTimes(streamsofroom[0].uuid, reportdate);
            roomtopalarms = await getTopAlarms(streamsofroom[0].uuid, reportdate);

            const groupedData = streamtimes.reduce((acc, current) => {
                const detectionType = current.DetectionType;
                const time = current.Time;
                const [hours, minutes, seconds] = time.split(':').map(Number);
                const totalSeconds = hours * 3600 + minutes * 60 + seconds;
              
                if (!acc[detectionType]) {
                  acc[detectionType] = { DetectionType: detectionType, Time: 0 };
                }
              
                acc[detectionType].Time += totalSeconds;
              
                return acc;
              }, {});
              
            streamtimesresult = Object.values(groupedData).map((item) => {
                const hours = Math.floor(item.Time / 3600);
                const minutes = Math.floor((item.Time % 3600) / 60);
                const seconds = item.Time % 60;
                item.Time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                return item;
              });

            setRoomtop(roomtopalarms);
            if(!dayweekvalue || dayweekvalue && dayweekvalue === 'day'){
                activities = await functions.getAlarmsFromRoom(streamsofroom[0].uuid, streamsofroom[0].name, reportdate);

                document.getElementById('chart').innerHTML = '';
                loadChart(reportdate, streams, activities);
            } else if(dayweekvalue && dayweekvalue === 'week') {
                weekactivities = await functions.getWeekAlarmsFromRoom(streamsofroom[0].uuid, streamsofroom[0].name, reportdate);
                loadChart(reportdate, weekdates, weekactivities);

                var div = document.getElementsByClassName('sg-gantt');
                if(div.length == 2){
                    div[1].remove();
                }
            }
            
        }else{
            var streams = [
                {
                    id: stream.name,
                    label: stream.name
                }
            ]

            var weekdates = getCurrentWeek(reportdate);

            setCurrentstream(stream);
            streamtimes = await getStreamTimes(stream.uuid, reportdate);
            roomtopalarms = await getTopAlarms(stream.uuid, reportdate);

            const groupedData = streamtimes.reduce((acc, current) => {
                const detectionType = current.DetectionType;
                const time = current.Time;
                const [hours, minutes, seconds] = time.split(':').map(Number);
                const totalSeconds = hours * 3600 + minutes * 60 + seconds;
              
                if (!acc[detectionType]) {
                  acc[detectionType] = { DetectionType: detectionType, Time: 0 };
                }
              
                acc[detectionType].Time += totalSeconds;
              
                return acc;
              }, {});
              
            streamtimesresult = Object.values(groupedData).map((item) => {
                const hours = Math.floor(item.Time / 3600);
                const minutes = Math.floor((item.Time % 3600) / 60);
                const seconds = item.Time % 60;
                item.Time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                return item;
              });

            setRoomtop(roomtopalarms);
            if(!dayweekvalue || dayweekvalue && dayweekvalue === 'day'){
                activities = await functions.getAlarmsFromRoom(stream.uuid, stream.name, reportdate);
                document.getElementById('chart').innerHTML = '';
                loadChart(reportdate, streams, activities);
            } else if(dayweekvalue && dayweekvalue === 'week') {
                weekactivities = await functions.getWeekAlarmsFromRoom(stream.uuid, stream.name, reportdate);
                loadChart(reportdate, weekdates, weekactivities);

                var div = document.getElementsByClassName('sg-gantt');
                if(div.length == 2){
                    div[1].remove();
                }
            }
            
            
        }

        var timeskeys = [];
        var timesvalues = [];
        var topkeys = [];
        var topvalues = []

        const detectionsArray = [
            { id: 0, label: t('nodetection') },
            { id: 1, label: t('mandown') },
            { id: 2, label: t('soeb') },
            { id: 3, label: t('outofbed') },
            { id: 4, label: t('outofroom') },
            { id: 5, label: t('inbed') },
            { id: 6, label: t('inbathroom') },
            { id: 7, label: t('missingbed') },
            { id: 8, label: t('personvisible') },
            { id: 9, label: t('getupfromchair') },
            { id: 10, label: t('getupinbed') },
            { id: 11, label: t('staffentering') },
            { id: 12, label: t('camunreachable') },
            { id: 13, label: t('wandering') }
        ];

        if(streamtimesresult && streamtimesresult.length > 0){
            for(var i = 0; i < streamtimesresult.length; i++){
                if(streamtimesresult[i] && streamtimesresult[i].Time){
                    var hours = streamtimesresult[i].Time.split(":")[0];
                    var minutes = streamtimesresult[i].Time.split(":")[1];
    
                    var durationinminutes = parseInt(hours) * 60 + parseInt(minutes);
                    var detectiontype = detectionsArray.find((alarm) => alarm.id == streamtimesresult[i].DetectionType);
                    var typeandtime = detectiontype.label + ': ' + streamtimesresult[i].Time;
                    
                    if(!timeskeys.includes(detectiontype)){
                        timeskeys.push(typeandtime);
                        timesvalues.push(durationinminutes);
                    }
                }
            }
        }

        if(roomtopalarms && roomtopalarms.length > 0){
            for(var i = 0; i < roomtopalarms.length; i++){
                var detectiontype = detectionsArray.find((alarm) => alarm.id == roomtopalarms[i].DetectionType);
                if(detectiontype){
                    if(!topkeys.includes(detectiontype.label)){
                        topkeys.push(detectiontype.label);
                        topvalues.push(roomtopalarms[i].Count);
                    }
                }
            }
        }
        initDoughnut($("#times"), timeskeys, timesvalues);
        initHorizontal($("#topalarms"), topkeys, topvalues);

        setLoadingScreen(false);
    }

    useEffect(() => {
        getData(currentstream ? currentstream : '', props.router.params.reportdate);
    }, [])

    return(
        <React.Fragment>
            <LocationAlert />
            {loadingScreen && (<div className="loadingScreen"><div className="loader"></div></div>)}
            <div className='loc_content roominsights'>
            <h1>{room.roomname}: {currentstream ? currentstream.name : ''}</h1>
                <div className="filters">
                    <div className="row">
                        <div className='col-md-6'>
                        <Filter type="date" text={t('date')} callback={async (data, e) => await changeDateData(data, currentstream ? currentstream : [])} />
                        {t('stream')}:
                        <select id="streamsOfRoom" value={currentstream ? currentstream.uuid : 0} onChange={(event) => setCurrentStream(event.target.value, currentday ? currentday : props.router.params.reportdate)}>
                            {roomstreams.map((stream, i) => {
                                if(stream){
                                    return <option key={i} data-value={stream.name} value={stream.uuid}>{stream.name}</option>
                                }
                                
                            })}
                        </select>
                        </div>

                        {localStorage.getItem('Role') === "2" || localStorage.getItem('Role') === "5" ? 
                            <div className='col-md-6'>
                                <div style={{ display: roleSpecs && roleSpecs[0] && roleSpecs[0].isadmin === 1 ? 'block' : 'none' }} onClick={() => { navigate("/manage/nightnurse/" + props.router.params.id) }} className="btn btn-blue"><i style={{ marginRight: "5px", color: '#ffffff' }} className="fa-solid fa-gear"></i> {t('roomsettings')}</div>
                            </div> : "" }
                    </div>
                </div>
                
                    <div className='row'>
                    {currentstream ? 
                        <div className='col-md-12'>
                            

                            <div className='chart-view' style={{ marginBottom: '20px' }}>
                                <div className='switchbtns' style={{ float: 'right' }}>
                                    <button style={{ background: 'none', border: 'none', color: '#00529c' }} id="showday" onClick={() => changedayweek('day')}>{t('day')}</button>
                                    <button style={{ background: 'none', border: 'none', color: '#00529c' }} id="showweek" onClick={() => changedayweek('week')}>{t('week')}</button>
                                </div>

                                <div className="streamtitle">{t('activityreports')}</div>
                                <div className='legend' style={{ marginBottom: "20px", color: '#333', fontWeight: '300'  }}>
                                    <div className='legend-item'>
                                        <div className='legend-block ALARM_IN_BED_DETECTION'></div> {t('inbed')}
                                    </div>
                                    <div className='legend-item'>
                                        <div className='legend-block ALARM_SOEB_DETECTION'></div> {t('soeb')}
                                    </div>
                                    <div className='legend-item'>
                                        <div className='legend-block ALARM_OUT_OF_BED_DETECTION'></div> {t('outofbed')}
                                    </div>
                                    <div className='legend-item'>
                                        <div className='legend-block ALARM_MAN_DOWN_DETECTION'></div> {t('fall')}
                                    </div>
                                    <div className='legend-item'>
                                        <div className='legend-block ALARM_IN_BATHROOM_DETECTION'></div> {t('inbathroom')}
                                    </div>
                                    <div className='legend-item'>
                                        <div className='legend-block ALARM_PERSON_VISIBLE_DETECTION'></div> {t('personvisible')}
                                    </div>
                                    <div className='legend-item'>
                                        <div className='legend-block ALARM_OUT_OF_ROOM_DETECTION'></div> {t('outofroom')}
                                    </div>
                                    <div className='legend-item'>
                                        <div className='legend-block ALARM_MISSING_BED_DETECTION'></div> {t('nobed')}
                                    </div>
                                    <div className='legend-item'>
                                        <div className='legend-block ALARM_NO_DETECTION'></div> {t('nodetection')}
                                    </div>
                                </div>
                                
                                <div id="chart"></div>
                            </div>
                        </div> : ''}

                        {currentstream ? 
                        <div className='col-md-6'>
                            <div className='teamDetails' style={{ margin: '0px 0px 0 40px' }}>
                                <div className="streamtitle">{t('totalactivityduration')}</div>
                                <div className='streamtimes'>
                                    <div className='row'>
                                        <div className='col-md-6'><canvas className="line-graph" id="times" width="400"></canvas></div>
                                        <div className='col-md-6'><div id="legend" className="legend-day"></div></div>
                                    </div>
                                </div>
                            </div>
                        </div> : ''}

                        {currentstream ? 
                        <div className='col-md-6'>
                            <VirtualRoundCard roomid={props.router.params.id} />
                            {/* <div className='teamDetails' style={{ borderLeft: 'solid 5px rgb(92, 184, 92)' }}>
                                {t('currentstatus')}: {t('inbed')}
                            </div>
                            <div className='teamDetails' style={{ borderLeft: 'solid 5px rgb(92, 184, 92)' }}>
                                {t('nocriticalincident48hours')}
                            </div> */}
                        </div> : ''}
                    </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(RoomInsightsList)