import React, { useEffect, useState } from 'react';
import withRouter from '../../components/withRouter';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
moment.locale('nl');

const AlarmsSidebar = () => {
    const { t } = useTranslation();
    const [alarms, setAlarms] = useState([]);
    const [countAlarms, setCountAlarms] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const getData = async () => {
        const functions = await import("../../Functions.js");
        const data = await functions.fetchApi("mba", "/alarm/get", "GET", {
            "locationid": localStorage.getItem('currentLocation')
        });
        var alarmcounter = 0;

        data.forEach(alarm => {
            alarmcounter++;
        })

        setCountAlarms(localStorage.getItem('currentLocation') && localStorage.getItem('currentLocation') !== '' && localStorage.getItem('currentLocation') != 0 ? alarmcounter : 0);
        setAlarms(localStorage.getItem('currentLocation') && localStorage.getItem('currentLocation') !== '' && localStorage.getItem('currentLocation') != 0 ? data : []);
    }

    useEffect(() => {
        getData();

        document.querySelectorAll('.alarmNotices').forEach(function (elem) {
            elem.addEventListener('click', function () {
                document.querySelector(".latest-alarms").classList.add('slide-in');
            });
        });
        
        document.querySelectorAll('.closeLatestAlarms').forEach(function (elem) {
            elem.addEventListener('click', function () {
                document.querySelector(".latest-alarms").classList.remove('slide-in');
            });
        });
    }, [])

    var limitAlarms = 0;

    return (
        <React.Fragment>
            <span className="header-icon alarmNotices">
                <i className="fas fa-bell"></i>
                <span className="header-badge badge rounded-pill bg-danger">{countAlarms}</span>
            </span>
            <div className="latest-alarms">
                <div className="closeLatestAlarms">
                    <i className="fas fa-times"></i>
                </div>

                <h6 className="latestAlarms_title"><i className="fas fa-users"></i> {t('alarms')}</h6>

                {!localStorage.getItem("currentLocation") || localStorage.getItem("currentLocation") && localStorage.getItem("currentLocation") !== '' ?
                    <div style={{ display: localStorage.getItem("currentLocation") && localStorage.getItem("currentLocation") !== '' ? "none" : "block" }} className="alert alert-warning msg_no_location" role="alert">
                        {t('location_not_selected')}
                    </div> : ''}

                {localStorage.getItem("currentLocation") && localStorage.getItem("currentLocation") !== '' ? 
                    alarms.map((alarm, i) => {
                        limitAlarms += 1;
                        const StatusColor = alarm.date !== null && alarm.date !== '' && alarm.down !== null && alarm.down !== '' ? '#FFA500' : (alarm.finish !== null && alarm.finish !== '') ? 'rgb(92, 184, 92)' : '#FF0000';

                        return (limitAlarms <= 8 ?
                            <a key={i} href={`/alarm/${alarm.id}`}>
                                <div className="alarm" style={{ borderLeft: `solid 5px ${StatusColor}` }}>
                                    <strong>{t(`${alarm.slug}`)}</strong><br />
                                    {moment(alarm.date).format('DD-MM-YYYY HH:mm')}<br />
                                    {alarm.object}
                                </div>
                            </a>
                        : '')
                    })
                : ''}

                {localStorage.getItem("currentLocation") && localStorage.getItem("currentLocation") !== '' ? 
                    <a className="alarms-viewMore" href={`/alarms/open`}>{t('viewallalarms')}</a> : '' }
            </div>
        </React.Fragment>
    );
}

export default withRouter(AlarmsSidebar);