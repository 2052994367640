import * as moment from 'moment';
import Chart from 'chart.js/auto';
import { fetchApi } from "../Functions";
const $ = require('jquery');
moment.locale('nl');

export const initLineChart = (element, labels, data) => {
  if (element) {
    const existingChart = Chart.getChart(element);
    if (existingChart) {
      existingChart.destroy();
    }

    new Chart(element, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            borderColor: '#0085CA',
            pointBackgroundColor: '#0085CA',
            borderWidth: 2,
            fill: false,
            tension: 0
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            grid: {
              display: false
            },
            ticks: {
              beginAtZero: true
            }
          },
          y: {
            ticks: {
              beginAtZero: true
            }
          }
        }
      }
    });
  }
};

export const initBarChart = (element, labels, data) => {
  if (element && labels && data) {
    const existingChart = Chart.getChart(element);
    if (existingChart) {
      existingChart.destroy();
    }

    new Chart(element, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: data
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            mode: 'index',
            intersect: false
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: true
            },
            stacked: true
          },
          x: {
            beginAtZero: true,
            grid: {
              display: false
            },
            stacked: true
          }
        }
      }
    });
  }
};

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'block';
    listContainer.style.flexDirection = 'row';
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach(item => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'block';
      li.style.flexDirection = 'row';
      li.style.marginLeft = '10px';

      li.onclick = () => {
        const {type} = chart.config;
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + 'px';
      boxSpan.style.display = 'inline-block';
      boxSpan.style.flexShrink = 0;
      boxSpan.style.height = '20px';
      boxSpan.style.marginRight = '10px';
      boxSpan.style.width = '20px';
      boxSpan.style.borderRadius = "50%";

      // Text
      const textContainer = document.createElement('p');
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = 0;
      textContainer.style.padding = 0;
      textContainer.style.display = 'inline-block';
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  }
};

export const initDoughnut = (element, labels, data) => {
  if (element && labels && data) {
    const existingChart = Chart.getChart(element);
    if (existingChart) {
      existingChart.destroy();
    }

    const doughnutChart = new Chart(element, {
      type: 'doughnut', 
      data: {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: [
            //'#00529c', '#1969b1', '#3178b9', '#5f9ad0', '#76adde', '#c3d7e9', '#54c6e9', '#01ace1'
            '#d0b2cf', '#b66f2b', '#003C72', '#ecdb0e', '#a01407', '#dc3545', '#25b100', '#01ace1', '#808080', 'red', 'grey'
          ],
          borderRadius: '100%'
        }],
      },
      options: {
        elements: {
          arc: {
            borderWidth: 5
          }
        },
        segmentShowStroke: true,
        responsive: true,
        plugins: {
          htmlLegend: {
            // ID of the container to put the legend in
            containerID: 'legend'
          },
          legend: {
            display: false,
          }
        }
      },
      plugins: [htmlLegendPlugin]
    });
  }
};

export const initHorizontal = (element, labels, data, colors) => {
  if (element && labels && data) {
    const existingChart = Chart.getChart(element);
    if (existingChart) {
      existingChart.destroy();
    }

    new Chart(element, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: colors
        }]
      },
      options: {
        indexAxis: 'y',
        responsive: true,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            barThickness: 30,
            beginAtZero: true,
            min: 0,
            stepSize: 1,
            grid: {
              display: true
            }
          },
          y: {
            grid: {
              display: false
            }
          }
        }
      }
    });
  }
};

export const getAlarms24Hours = (alarmdata) => {
  var alarms24Hours = [],
    alarms24HoursCounts = [],
    today = new Date(),
    currentHour = today.getHours(),
    first = true,
    secondLoop = 0,
    ii,
    data = [];

  for (var i = 0; i < 24; i++) {
    data[i] = [0, 0];
  }

  alarmdata.forEach(function (alarm) {
    var nn = 0;

    if (alarm.minutes >= '30') {
      nn = 1;
    }

    data[alarm.hour][nn] = parseInt(alarm['nrofalarms']);
  });

  if (currentHour < 23) {
    secondLoop = 24 - (23 - currentHour);
  }

  if (secondLoop != 0) {
    for (ii = secondLoop; ii < data.length; ii++) {
      if (!first) { }
      else { first = false; }

      alarms24Hours.push(`${ii}:00`);
      alarms24HoursCounts.push(`${data[ii][0]}`);
      alarms24Hours.push(`${ii}:30`);
      alarms24HoursCounts.push(`${data[ii][1]}`);
    }
  }

  var begin = currentHour - 23;
  if (begin < 0) { begin = 0; }

  for (ii = begin; ii <= currentHour; ii++) {
    if (!first) { }
    else { first = false; }

    alarms24Hours.push(`${ii}:00`);
    alarms24HoursCounts.push(`${data[ii][0]}`);
    alarms24Hours.push(`${ii}:30`);
    alarms24HoursCounts.push(`${data[ii][1]}`);
  }

  var element = $('#alarms');

  initLineChart(element, alarms24Hours, alarms24HoursCounts);

  return data;
}

export const getAlarms7Days = (alarmdata) => {
  var i;
  var data = [];
  for (i = 0; i < 8; i++) {

    var date = moment();
    date = date.subtract(i, 'days');
    date = date.format("DD-MM-YYYY");

    data[date] = 0;
  }

  alarmdata.forEach(function (alarm) {
    if (alarm.day < 10) {
      var day = '0' + alarm.day;
    } else {
      day = alarm.day
    }

    if (alarm.month < 10) {
      var month = '0' + alarm.month;
    }
    else {
      month = alarm.month
    }

    data[day + '-' + month + '-' + alarm.year] += parseInt(alarm['nrofalarms']);
  });

  var alarms7Days = [];
  var alarms7DaysCounts = [];

  var first = true;
  var secondLoop = 0;

  for (var ii = secondLoop; ii < 8; ii++) {
    if (!first) { }
    else { first = false; }

    var date = moment();
    date = date.subtract(ii, 'days');
    date = date.format("DD-MM-YYYY");

    alarms7Days.push(date);
    alarms7DaysCounts.push(data[date]);
  }

  var alarms7days = $("#alarms");

  initLineChart(alarms7days, alarms7Days, alarms7DaysCounts);

  return data;
}

export const getAlarmsMonth = (alarmdata) => {
  var i;
  var data = [];
  for (i = 0; i < 31; i++) {

    var date = moment();
    date = date.subtract(i, 'days');
    date = date.format("DD-MM-YYYY");

    data[date] = 0;
  }

  alarmdata.forEach(function (alarm) {
    if (alarm.day < 10) {
      var day = '0' + alarm.day;
    } else {
      day = alarm.day
    }

    if (alarm.month < 10) {
      var month = '0' + alarm.month;
    }
    else {
      month = alarm.month
    }
    data[day + '-' + month + '-' + alarm.year] += parseInt(alarm['nrofalarms']);
  });


  var alarmsMonth = [];
  var alarmsMonthCounts = [];

  var first = true;
  var secondLoop = 0;

  for (var ii = secondLoop; ii < 31; ii++) {
    if (!first) { }
    else { first = false; }

    var date = moment();
    date = date.subtract(ii, 'days');
    date = date.format("DD-MM-YYYY");

    alarmsMonth.push(date);
    alarmsMonthCounts.push(data[date]);
  }

  var alarmsmonth = $("#alarms");

  initLineChart(alarmsmonth, alarmsMonth, alarmsMonthCounts);

  return data;
}

export const getAlarmsYear = (alarmdata) => {

  var data = [];

  for (var i = 0; i < 13; i++) {

    var date = moment();
    date = date.subtract(i, 'months');
    date = date.format("MM-YYYY");

    data[date] = 0;
  }

  if(alarmdata && alarmdata.length > 0 && alarmdata !== 'undefined'){
    alarmdata.forEach(function (alarm) {

      if (alarm.month < 10) {
        var month = '0' + alarm.month;
      }
      else {
        month = alarm.month
      }
  
      if (alarm['nrofalarms']) {
        data[month + '-' + alarm.year] += parseInt(alarm.nrofalarms);
      } else {
        data[month + '-' + alarm.year] = 0;
      }
  
    });
  }

  var alarmsYear = [];
  var alarmsYearCounts = [];

  for (var j = 0; j < 12; j++) {

    var date = moment();
    date = date.subtract(j, 'months');
    date = date.format("MM-YYYY");

    alarmsYear.push(date);
    alarmsYearCounts.push(data[date]);
  }

  var alarmsyear = $("#alarms");

  initLineChart(alarmsyear, alarmsYear, alarmsYearCounts);

  return data;
}

export const getAlarmsTop10_24Hours = async (t) => {
  var alarms10 = $("#alarmstop");
  const data = await fetchApi("mba", "/mba/stats/topalarms24hours", "GET", { "locationid": localStorage.getItem('currentLocation') });

  const Top10AlarmNames = data.map(alarm => t(alarm.slug));
  const Top10AlarmNumbers = data.map(alarm => alarm.alarms);

  initDoughnut(alarms10, Top10AlarmNames, Top10AlarmNumbers);
  return data;
}

export const getAlarmsTop10_7Days = async (t) => {
  var alarms10 = $("#alarmstop");
  const data = await fetchApi("mba", "/mba/stats/topalarms7days", "GET", { "locationid": localStorage.getItem('currentLocation') });

  const Top10AlarmNames = data.map(alarm => t(alarm.slug));
  const Top10AlarmNumbers = data.map(alarm => alarm.alarms);

  initDoughnut(alarms10, Top10AlarmNames, Top10AlarmNumbers);
  return data;
};

export const getTeams = async () => {
  const locationid = localStorage.getItem('currentLocation');
  const data = await fetchApi("mba", "/mba/stats/teams", "GET", { locationid });

  const [PeopleInTeam, TeamNames, TeamColors, TeamIds] = [[], [], [], []];

  data.forEach(({ color, nrofpeopleinteams, name, teamid }) => {
    PeopleInTeam.push(nrofpeopleinteams);
    TeamNames.push(name);
    TeamIds.push(teamid);
    TeamColors.push(color === 'blue' ? '#00529c' : color);
  });

  initHorizontal($("#team"), TeamNames, PeopleInTeam, '#0085CA');
  return data;
};

export const getRoomsTop10_24Hours = async (t) => {
  const roomchart = $("#rooms");
  const alarms = await fetchApi("mba", "/mba/stats/rooms24hours", "GET", { locationid: localStorage.getItem('currentLocation') });

  const labels = [...new Set(alarms.map(alarm => alarm.room))];
  const alarmsArray = [...new Set(alarms.map(alarm => t(alarm.slug)))];
  const rooms = [...new Set(alarms.map(alarm => alarm.room))];

  const data = alarmsArray.map(alarmSlug => {
    const roomData = rooms.map(room => {
      const match = alarms.find(alarm => t(alarm.slug) === alarmSlug && alarm.room === room);
      return match ? match.counting : 0;
    });

    return { label: alarmSlug, data: roomData, backgroundColor: '#0085ca' };
  });

  initBarChart(roomchart, labels, data);
  return alarms;
}

export const getRoomsTop10_7Days = async (t) => {
  const roomchart = $("#rooms");
  const alarms = await fetchApi("mba", "/mba/stats/rooms7days", "GET", { locationid: localStorage.getItem('currentLocation') });

  const labels = [...new Set(alarms.map(alarm => alarm.room))];
  const alarmsArray = [...new Set(alarms.map(alarm => t(alarm.slug)))];
  const rooms = [...new Set(alarms.map(alarm => alarm.room))];

  const data = alarmsArray.map(alarmSlug => {
    const roomData = rooms.map(room => {
      const match = alarms.find(alarm => t(alarm.slug) === alarmSlug && alarm.room === room);
      return match ? match.counting : 0;
    });

    return { label: alarmSlug, data: roomData, backgroundColor: '#0085ca'};
  });

  initBarChart(roomchart, labels, data);
  return alarms;
}