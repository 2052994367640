import React, { Component, useEffect, useState } from 'react';
import '../../assets/style.css';
import withRouter from '../../components/withRouter';
import * as moment from 'moment';
import { crudAlertControl, initDataTable, fetchApi } from "../../Functions.js";
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
moment.locale('nl');

const TeamDetails = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [team, setTeam] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [locations, setLocations] = useState([]);

    const changeData = async (event) => {
        event.preventDefault();
        const data = await fetchApi("mba", `/mba/team/update/${props.router.params.id}`, "GET", {
            "teamid": props.router.params.id, "name": event.target.name.value, "locationid": event.target.related.value, "queue": event.target.pbx.value
        })
        await getData();
        crudAlertControl('update', data);
    }

    const getData = async (init) => {
        const [data, employees, objects] = await Promise.all([
            fetchApi("mba", `/mba/team/get/${props.router.params.id}`, "GET", {
                "teamid": props.router.params.id, "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : '', "showHidden": true
            }),
            fetchApi("mba", `/mba/team/employees/get/${props.router.params.id}`, "GET"),
            fetchApi("mba", "/mba/object/get", "GET", {
                "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
            })
        ])
        setTeam(data[0]);
        setEmployees(employees);
        setLocations(objects);
        initDataTable();
    }

    useEffect(() => {
        getData(true);
    }, [])

    return (
        <React.Fragment>
            <div className='loc_content'>
                <form type="POST" onSubmit={(event) => changeData(event)}>
                    <input className="btn btn-save" style={{ marginTop: '0' }} type="submit" name="submit" value={t('save')} />
                    <h1><button className="goBackBtn" onClick={() => navigate('/manage/teams')}><i class="fas fa-arrow-circle-left"></i></button> {t('configuration')}</h1>
                
                    <div className="alert alert-success alert-update" role="alert">
                        {t('updateteam')}
                    </div>

                    <div class="alert alert-danger" role="alert">
                        {t('somethingwrong')}
                    </div>

                    <div class="orgDetails">
                    <div className="table-responsive">
                        <table class="table">
                            <tr class="rowodd">
                                <td class="td">{t('id')}</td>
                                <td class="td">{team.teamid}</td>
                            </tr>
                            <tr class="roweven">
                                <td class="td">{t('name')}</td>
                                <td class="td"><input type="text" name="name" id="name" defaultValue={team.name} className="form-control" /></td>
                            </tr>
                            <tr class="rowodd">
                                <td class="td">{t('location')}</td>
                                <td class="td">
                                    <select name="related" id="related" className="form-control">
                                    <option value="0">{t('select')}</option>
                                        {locations.map((location, i) => {
                                            var selected = team.locationid === location.objectid ? true : false;
                                            return (<option value={location.objectid} selected={selected}>{location.objectname}</option>)
                                        })}
                                    </select>
                                </td>
                            </tr>
                            <tr class="roweven">
                                <td class="td">{t('pbxqueuenumber')}</td>
                                <td class="td"><input type="text" name="pbx" id="pbx" defaultValue={team.pbxqueuenum} className="form-control" /></td>
                            </tr>
                        </table>
                        </div>
                    </div>
                </form>

                <h2>{t('currentmembers')}</h2>

                <div className="DataTable_Container">
                <div className="table-responsive">
                    <table id="DataTable" class="showHead table responsive nowrap" width="100%">
                        <thead>
                            <tr>
                                <th>{t('employee')}</th>
                                <th>{t('logintime')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employees.map((employee, i) => {
                            return (
                                <tr>
                                <td>{employee.employeename}</td>
                                <td>{moment(employee.logindatetime).format('DD-MM-YYYY HH:mm:ss')}</td>
                                </tr>
                            )
                            })}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(TeamDetails);
