import React, { useState, useEffect } from 'react'
import withAuth from '../Login/withAuth';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { confirmDelete, fetchApi } from "../../Functions";
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import '../../assets/style.css';
import { useTranslation } from 'react-i18next';
import ModalDelete from '../ModalDelete';
const $ = require('jquery');
moment.locale('nl');

const Editor = () => {
    const { t } = useTranslation();
    const [tabscards, setTabscards] = useState([]);
    const [tabindex, setTabindex] = useState(0);
    const [currenttab, setCurrenttab] = useState(0);
    const [rooms, setRooms] = useState([]);
    const [employees, setEmployees] = useState([]);

    const getAllTabsWithCards = async () => {
        const tabs = await fetchApi("mba", "/mba/cards/tabs", "GET");
        await Promise.all(tabs.map(async (tab, index, receive) => {
            const cards = await fetchApi("mba", `/mba/cards/tabs/${tab.id}`, "GET", {
                "tabid": tab.id
            });
            tab['cards'] = cards;
        }))

        setTabscards(tabs);
        setCurrenttab(localStorage.getItem('currenttab') && tabs.length > 1 ? tabs[localStorage.getItem('currenttab')].id : (tabs[0] ? tabs[0].id : ''));
    }

    const addTab = async (event) => {
        event.preventDefault();
        if(event.target[0].value && event.target[0].value !== ''){
            const tab = await fetchApi("mba", "/mba/cards/tabs/insert", "GET", {
                "tabname": event.target[0].value
            });
            $('.modal .tabname').val('');
            $('.modal').hide();
            getAllTabsWithCards();
        }
    }

    const delTab = async (event, tabid) => {
        event.preventDefault();
        const tab = await fetchApi("mba", `/mba/cards/tabs/${tabid}/delete`, "GET", {
            "tabid": tabid
        });
        
        $('.modal').hide();
        getAllTabsWithCards();
    }

    const addCard = async (event, type) => {
        event.preventDefault();
        let cardid, cardName, cardSize, param;

        switch (type) {
          case 'rooms':
            cardid = document.getElementById('rooms').value;
            cardName = document.querySelector("#rooms option:checked").textContent;
            cardSize = document.getElementById('rooms-cardsize').value;
            param = 'roomid';
            break;
          case 'employees':
            cardid = document.getElementById('employees').value;
            cardName = document.querySelector("#employees option:checked").textContent;
            cardSize = document.getElementById('employees-cardsize').value;
            param = 'employeeid';
            break;
          case 'graphs':
            cardid = document.getElementById('graphs').value;
            cardName = document.querySelector("#graphs option:checked").textContent;
            cardSize = document.getElementById('graphs-cardsize').value;
            param = 'overviewid';
            break;
          default:
            cardid = event.target.dataset.id;
            cardName = event.target.dataset.name;
            cardSize = event.target.dataset.size || 6;
            param = event.target.dataset.param;
        }

        const [cards, latestcard] = await Promise.all([
            await fetchApi("mba", "/mba/cards/insert", "GET", {
                "name": cardName, 'tabid': currenttab, 'cardsize': cardSize,
            }),
            await fetchApi("mba", "/mba/cards/latest/", "GET")
        ])

        await Promise.all(latestcard.map(async (latest, index, receive) => {
            const params = await fetchApi("mba", `/mba/cards/${latest.id}/insert/parameter`, "GET", {
                "cardid": latest.id, "parameter": param, "value": cardid
            })
            document.querySelector('.modal #graphs').value = 0;
            document.querySelector('.modal #rooms').value = 0;
            document.querySelector('.modal #employees').value = 0;

            document.querySelector('.modal #graphs-cardsize').value = 0;
            document.querySelector('.modal #rooms-cardsize').value = 0;
            document.querySelector('.modal #employees-cardsize').value = 0;

            $('.modal').hide();
            getAllTabsWithCards();
        }))
    }

    const delCard = async (cardid) => {
        const data = await fetchApi("mba", `/mba/cards/${cardid}/delete`, "GET", {
            "cardid": cardid
        })
        $('.modal').hide();
        getAllTabsWithCards();
    }

    const getData = async () => {
        const [getrooms, getemployees] = await Promise.all([
            fetchApi("mba", "/mba/room/get", "GET", {
                "locationid": localStorage.getItem('currentLocation')
            }),
            fetchApi("mba", "/mba/phonebook/get/", "GET", {
                "locationid": localStorage.getItem('currentLocation')
            })
        ])

        setRooms(getrooms);
        setEmployees(getemployees);
    }

    useEffect(() => {
        getAllTabsWithCards();
        getData();

        document.querySelector('.btn-add-tab').addEventListener('click', function () {
            document.getElementById('add-tab').style.display = 'block';
        });

        const addGraphs = document.querySelectorAll('.addGraph');
        addGraphs.forEach(graph => {
            graph.addEventListener('click', function () {
                const graphsInput = document.querySelector('#add-card-graph #graphs');
                graphsInput.value = this.dataset.id;
                document.getElementById('add-card-graph').style.display = 'block';
            });
        });

        const closeModalButtons = document.querySelectorAll('.modal .close, .modal .cancel');
        closeModalButtons.forEach(button => {
            button.addEventListener('click', function () {
                const modal = this.closest('.modal');
                modal.style.display = 'none';
            });
        });

        document.querySelector('.addRoom').addEventListener('click', function () {
            document.getElementById('add-card-rooms').style.display = 'block';
        });

        document.querySelector('.addEmployee').addEventListener('click', function () {
            document.getElementById('add-card-employees').style.display = 'block';
        });


        setTimeout(() => {
            if(!tabscards || (tabscards && tabscards.length == 0)){
                const btnAddTab = document.querySelector('.btn-add-tab');
                btnAddTab.style.backgroundColor = '#dae4f6';
            } 
        }, 1000);
    }, []);

    return (
        <div className='dashboard-editor'>
            <div className='tileCatalogue'>
                <ul>
                    <li className='btn-add-tab'><i className="fa-solid fa-window-maximize"></i> {t('tab')}</li>
                    <li className="addGraph" data-id="1" data-type="graphs" data-name={t('historyalarms')} data-param="overviewid"><i className="fas fa-chart-area"></i> {t('historyalarms')}</li>
                    <li className="addGraph" data-id="2" data-type="graphs" data-name={t('topalarms')} data-param="overviewid"><i className="fas fa-chart-pie"></i> {t('topalarms')}</li>
                    <li className="addGraph" data-id="3" data-type="graphs" data-name={t('top10rooms')} data-param="overviewid"><i className="fas fa-chart-column"></i> {t('top10rooms')}</li>
                    <li className="addGraph" data-id="4" data-type="graphs" data-name={t('employeesperteam')} data-param="overviewid"><i className="fas fa-chart-bar"></i> {t('employeesperteam')}</li>
                    <li className='addTips' onClick={(event) => addCard(event)} data-id="5" data-name={t('tips')} data-param="tips"><i class="fas fa-circle-info"></i> {t('tips')}</li>
                    <li className="addRoom" data-type="rooms"><i className="fas fa-door-open"></i> {t('room')}</li>
                    <li className='addEmployee' data-type="employees"><i className="fas fa-user"></i> {t('employee')}</li>
                    <li className="saveDash"><Link to='/' style={{ color: '#333333', textDecoration: 'none' }}><i class="fa-solid fa-arrow-right-from-bracket"></i> {t('closeeditor')}</Link></li>
                </ul>
            </div>
            <div className='content'>

                {!tabscards || tabscards && tabscards.length == 0 ?

                    <div>
                        <img className="empty-arrow" src="/images/arrow-dashboard.png" />
                        <h2 className="empty-title">{t('emptydashboard')}</h2>
                        <h2 className="empty-subtitle">{t('emptydashboardtext')}</h2>
                    </div>

                    : ""}


                <Tabs
                    selectedIndex={localStorage.getItem('currenttab') ? parseInt(localStorage.getItem('currenttab')) : 0}
                    onSelect={(index) => { 
                        localStorage.setItem('currenttab', index); 
                        setTabindex(localStorage.getItem('currenttab'));
                        setCurrenttab(localStorage.getItem('currenttab') && localStorage.getItem('currenttab').length > 1 ? tabscards[localStorage.getItem('currenttab')].id : tabscards[0].id);
                    }}>

                    <TabList>
                        {tabscards.map((tab, i) => {
                            return (
                                <Tab data-id={tab.id}>
                                    {tab.tabname}
                                    <i onClick={(event) => confirmDelete(t('delitem'), function () { delTab(event, tab.id) })} className="fas fa-trash-alt btn-del-tab"></i>
                                </Tab>)
                        })}
                    </TabList>

                    {tabscards.map((tab, i) => {
                        return (
                            <TabPanel>
                                <div className="container-fluid">
                                    <div className="row">
                                        {tab && tab.cards ? tab.cards.map((card, i) => {
                                            return (
                                                <div key={i} data-cardid={card.id} className={`col-md-${card.cardsize}`}>
                                                    <div className="card">
                                                        <h2>{card.cardname}
                                                            <div className='actions' style={{ float: 'right', marginTop: '0' }}>
                                                                <i onClick={(event) => confirmDelete(t('delitem'), function () { delCard(card.id) })} className="fas fa-trash-alt btn-del-card" data-id="{{ Card.id }}" style={{ cursor: 'pointer', color: '#ff0000' }}></i>
                                                            </div>
                                                        </h2>

                                                        <div className="card-body">

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : ''}
                                    </div>
                                </div>
                            </TabPanel>)
                    })}
                </Tabs>

                <div id="add-tab" className="modal fade in">
                    <form onSubmit={(event) => addTab(event, 'tab')}>
                        <div className="modal-content ">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('addtab')}</h4>
                                <div className="close">&times;</div>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <div className="form-group">
                                        <label for="cards">{t('name')}</label>
                                        <input type="text" className="form-control tabname" name="tabname" />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div id="cancel btnNo" className="btn btn-red cancel">{t('cancel')}</div>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="submit" name="submit" className="btn btn-green btn-add save">{t('add')}</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
                
                <ModalDelete />

                <div id="add-card-graph" className="modal fade in">
                    <form onSubmit={(event) => addCard(event, 'graphs')}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('addgraph')}</h4>
                                <div className="close">&times;</div>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label for="graphs">{t('graphs')}</label>
                                    <select name="graphs" id="graphs" className="form-control" disabled>
                                        <option value="0">{t('select')}</option>
                                        <option value="1">{t('historyalarms')}</option>
                                        <option value="2">{t('topalarms')}</option>
                                        <option value="3">{t('top10rooms')}</option>
                                        <option value="4">{t('employeesperteam')}</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label for="cardsize">{t('width')}</label>
                                    <select name="cardsize" id="graphs-cardsize" className="form-control cardsize">
                                        <option value="0">{t('select')}</option>
                                        <option value="12">{t('fullwidth')}</option>
                                        <option value="8">{t('threequarterwidth')}</option>
                                        <option value="6">{t('halfwidth')}</option>
                                        <option value="4">{t('quarterwidth')}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div id="cancel btnNo" className="btn btn-red cancel">{t('cancel')}</div>
                                    </div>
                                    <div className="col-md-6">
                                        <button data-type="graphs" type="submit" name="submit" className="btn btn-green btn-add save">{t('add')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div id="add-card-rooms" className="modal fade in">
                    <form onSubmit={(event) => addCard(event, 'rooms')}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('addroom')}</h4>
                                <div className="close">&times;</div>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label for="rooms">{t('room')}</label>
                                    <select name="rooms" id="rooms" className="form-control">
                                        <option value="0">{t('select')}</option>
                                        <option value="7">Room Blue</option>
                                        <option value="8">Room Yellow</option>
                                        <option value="9">Room Green</option>
                                        {/* {rooms.map((room, i) => {
                                            return (<option key={i} value={room.roomid}>{room.roomname}</option>)
                                        })} */}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label for="cardsize">{t('width')}</label>
                                    <select name="cardsize" id="rooms-cardsize" className="form-control cardsize">
                                        <option value="0">{t('select')}</option>
                                        <option value="12">{t('fullwidth')}</option>
                                        <option value="8">{t('threequarterwidth')}</option>
                                        <option value="6">{t('halfwidth')}</option>
                                        <option value="4">{t('quarterwidth')}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div id="cancel btnNo" className="btn btn-red cancel">{t('cancel')}</div>
                                    </div>
                                    <div className="col-md-6">
                                        <button data-type="rooms" type="submit" name="submit" className="btn btn-green btn-add save">{t('add')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div id="add-card-employees" className="modal fade in">
                    <form onSubmit={(event) => addCard(event, 'employees')}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('addemployee')}</h4>
                                <div className="close">&times;</div>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label for="employees">{t('employee')}</label>
                                    <select name="employees" id="employees" className="form-control">
                                        <option value="0">{t('select')}</option>
                                        {employees.map((employee, i) => {
                                            return (<option key={i} value={employee.id}>{employee.user}</option>)
                                        })}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label for="cardsize">{t('width')}</label>
                                    <select name="cardsize" id="employees-cardsize" className="form-control cardsize">
                                        <option value="0">{t('select')}</option>
                                        <option value="12">{t('fullwidth')}</option>
                                        <option value="8">{t('threequarterwidth')}</option>
                                        <option value="6">{t('halfwidth')}</option>
                                        <option value="4">{t('quarterwidth')}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div id="cancel btnNo" className="btn btn-red cancel">{t('cancel')}</div>
                                    </div>
                                    <div className="col-md-6">
                                        <button data-type="employees" type="submit" name="submit" className="btn btn-green btn-add save">{t('add')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default withAuth(Editor);