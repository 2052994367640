import React, { Component, useEffect, useState } from 'react';
import withAuth from '../../../components/Login/withAuth';
import * as moment from 'moment';
import LocationAlert from "../../../components/LocationAlert.js";
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
moment.locale('nl');

const EmployeeDashList = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [employees, setEmployees] = useState([]);

    const getData = async () => {
        const functions = await import("../../../Functions.js");
        const data = await functions.fetchApi("mba", "/mba/employee/get", "GET", {
            "locationid": localStorage.getItem('currentLocation')
        });
        setEmployees(data);

        functions.initDataTable();
        functions.locationAlert();
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <h1>{t('employeereports')}</h1>

                <div className="DataTable_Container">
                <div className="table-responsive">
                    <table id="DataTable" className="showHead table responsive nowrap" width="100%">
                        <thead>
                            <tr className="rowtitle">
                                <th>{t('name')}</th>
                                <th>{t('linkedto')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employees.map((employee, i) => {
                                return (
                                    <tr>
                                        <td onClick={(event) => { navigate(`/reports/employeedash/${employee.employeeid}/${moment().format('YYYY-MM')}`) }}>{employee.employeename}</td>
                                        <td>{employee.relatedname}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withAuth(EmployeeDashList);